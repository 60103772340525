const reducer = (state, action) => {
  const previousPages = {
    Login: "LoginOrSubscribe",
    ResetPassword: "Login",

    TravelerOrHostAccount: "LoginOrSubscribe",
    TravelerAccount: "TravelerOrHostAccount",
    TravelerAccountDetails: "TravelerAccount",

    PrivateHostOrProfessional: "TravelerOrHostAccount",
    ProfessionalHostAccount: "PrivateHostOrProfessional",
    ProfessionalHostAccountDetails: "ProfessionalHostAccount",

    PrivateHostAccount: "PrivateHostOrProfessional",
    PrivateHostAccountDetails: "PrivateHostAccount",
  };

  switch (action.type) {
    case "GO TO":
      if (action.to === "TravelerAccount") {
        return { ...state, userType: "traveler", openModal: action.to };
      } else if (action.to === "PrivateHostOrProfessional") {
        return { ...state, userType: "host", openModal: action.to };
      } else if (action.to === "AccountWellCreated") {
        window.localStorage.removeItem("CREATE_AN_ACCOUNT_STATE");
        return { ...state, openModal: action.to };
      } else if (action.to === "LoginOrSubscribe") {
        return { ...defaultState, openModal: action.to };
      } else {
        return { ...state, openModal: action.to };
      }

    case "BACK":
      return { ...state, openModal: previousPages[state.openModal] };

    case "SET":
      switch (action.kind) {
        case "EMAIL":
          return { ...state, email: action.target };
        case "PASSWORD":
          return { ...state, password: action.target };
        case "FIRST NAME":
          return { ...state, firstName: action.target };
        case "LAST NAME":
          return { ...state, lastName: action.target };
        case "PHONE NUMBER":
          return { ...state, phoneNumber: action.target };
        case "DATE":
          return { ...state, date: action.target };
        case "OWNER NAME":
          return { ...state, companyName: action.target };
        case "PERSON IN CHARGE NAME":
          return { ...state, personInChargeName: action.target };
        case "RCS SIREN SIRET":
          return { ...state, rcsSirenSiret: action.target };
        case "STREET NUMBER":
          return { ...state, streetNumber: action.target };
        case "STREET":
          return { ...state, street: action.target };
        case "ADDRESS COMPLEMENT":
          return { ...state, addressComplement: action.target };
        case "CITY":
          return { ...state, city: action.target };
        case "POSTAL CODE":
          return { ...state, postalCode: action.target };
        case "BIRTH DATE":
          return { ...state, birthDate: action.target };
        case "COUNTRY":
          return { ...state, country: action.target };
        case "USER CONDITIONS":
          return { ...state, userConditions: action.target };
      }
    case "SET ALL":
      return { ...state, ...action.target };

    case "CLOSE":
      return state.updateAccountInfos
        ? { ...state, openModal: "" }
        : defaultState;

    default:
      throw new Error();
  }
};

let defaultState = {
  openModal: "",
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  pseudo: "",
  description: "",
  phoneNumber: "",
  date: "",
  companyName: "",
  personInChargeName: "",
  rcsSirenSiret: "",
  streetNumber: "",
  street: "",
  addressComplement: "",
  city: "",
  postalCode: "",
  birthDate: "",
  country: "",
  userConditions: false,
  userType: "",
  updateAccountInfos: false,
  instantBooking: true,
  isPseudoUpdated: false,
  description: "",
  uid: "",
};
export { reducer, defaultState };
