import React, { useState, useEffect } from "react";
import AuthModal from "./AuthModal";
import { TextField } from "@mui/material";
import { IonIcon } from "@ionic/react";
import { calendarOutline } from "ionicons/icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import SelectCountry from "./SelectCountry";
import * as validator from "validator";
import useAuth from "../../hooks/useAuth";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import CircularProgress from "@mui/material/CircularProgress";

dayjs.locale("fr");

export function toUpperCamelCase(str) {
  var words = str.split(" ");
  var camelCaseStr =
    words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
  for (var i = 1; i < words.length; i++) {
    camelCaseStr +=
      words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }
  return camelCaseStr;
}

const ProfessionalHostAccountDetailsContent = () => {
  let defaultErrorForm = {
    companyNameError: false,
    personInChargeNameError: false,
    rcsSirenSiretError: false,
    phoneNumberError: false,
    streetNumberError: false,
    streetError: false,
    postalCodeError: false,
    cityError: false,
    countryError: false,
    generalError: false,
    generalErrorMessage: "",
  };
  const {
    RegisterWithEmailAndPassword,
    loginSubscribeState,
    loginSubscribeDispatch,
    UpdateUserInDatabase,
    getAvailablePseudo,
  } = useAuth();
  const [errorForm, setErrorForm] = useState(defaultErrorForm);
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = dayjs();
  const minDate = currentDate.subtract(100, "year");
  const maxDate = currentDate.subtract(18, "year");

  const buttonDisabled =
    loginSubscribeState.companyName === "" &&
    loginSubscribeState.personInChargeName === "" &&
    loginSubscribeState.rcsSirenSiret === "" &&
    loginSubscribeState.birthDate === "" &&
    loginSubscribeState.phoneNumber === "" &&
    loginSubscribeState.street === "" &&
    loginSubscribeState.postalCode === "" &&
    loginSubscribeState.city === "" &&
    loginSubscribeState.country === ""
      ? true
      : false;

  const handleChange = (event, kind) => {
    let target = event.target.value;

    if (
      kind === "RCS SIREN SIRET" ||
      kind === "PHONE NUMBER" ||
      kind === "POSTAL CODE"
    ) {
      if (target >= 0) {
        loginSubscribeDispatch({ type: "SET", kind: kind, target: target });
      }
    } else {
      loginSubscribeDispatch({ type: "SET", kind: kind, target: target });
    }
  };

  const validate = async (e) => {
    e.preventDefault();

    let newErrorForm = defaultErrorForm;

    newErrorForm["companyNameError"] =
      loginSubscribeState.companyName === "" && true;

    newErrorForm["personInChargeNameError"] =
      loginSubscribeState.personInChargeName === "" && true;

    newErrorForm["rcsSirenSiretError"] =
      !validator.isNumeric(loginSubscribeState.rcsSirenSiret) && true;

    newErrorForm["birthDateError"] =
      loginSubscribeState.birthDate === "" && true;

    newErrorForm["phoneNumberError"] =
      !validator.isMobilePhone(loginSubscribeState.phoneNumber, "fr-FR") &&
      true;

    newErrorForm["streetError"] = loginSubscribeState.street === "" && true;

    newErrorForm["postalCodeError"] =
      !validator.isPostalCode(loginSubscribeState.postalCode, "any") && true;

    newErrorForm["cityError"] = loginSubscribeState.city === "" && true;

    newErrorForm["countryError"] = loginSubscribeState.country === "" && true;

    let validate =
      !newErrorForm.companyNameError &&
      !newErrorForm.personInChargeNameError &&
      !newErrorForm.rcsSirenSiretError &&
      !newErrorForm.birthDateError &&
      !newErrorForm.phoneNumberError &&
      !newErrorForm.streetError &&
      !newErrorForm.postalCodeError &&
      !newErrorForm.cityError &&
      !newErrorForm.countryError;

    if (validate) {
      setIsLoading(true);

      let pseudoResponse = await getAvailablePseudo(
        toUpperCamelCase(loginSubscribeState.companyName)
      );

      let data = {
        companyName: loginSubscribeState.companyName,
        personInChargeName: loginSubscribeState.personInChargeName,
        birthDate: loginSubscribeState.birthDate,
        rcsSirenSiret: loginSubscribeState.rcsSirenSiret,
        phoneNumber: loginSubscribeState.phoneNumber,
        streetNumber: loginSubscribeState.streetNumber,
        street: loginSubscribeState.street,
        addressComplement: loginSubscribeState.addressComplement,
        postalCode: loginSubscribeState.postalCode,
        city: loginSubscribeState.city,
        country: loginSubscribeState.country,
        email: loginSubscribeState.email,
        password: loginSubscribeState.password,
        userType: loginSubscribeState.userType,
        pseudo: pseudoResponse.pseudo,
        description: loginSubscribeState.description,
        instantBooking: loginSubscribeState.instantBooking,
        isPseudoUpdated: loginSubscribeState.isPseudoUpdated,
        isProfessional: true,
      };


      if (loginSubscribeState.updateAccountInfos) {
        const res = await UpdateUserInDatabase(loginSubscribeState.uid, data);

        if (res.status === 200) {
          loginSubscribeDispatch({ type: "CLOSE" });
        } else {
          newErrorForm["generalError"] = true;

          newErrorForm["generalErrorMessage"] = res?.errorMessage;
        }
      } else {
        const res = await RegisterWithEmailAndPassword(data);

        if (res.statusCode === 200) {
          loginSubscribeDispatch({ type: "GO TO", to: "AccountWellCreated" });
        } else {
          newErrorForm["generalError"] = true;

          newErrorForm["generalErrorMessage"] = res?.errorMessage;
        }
      }

      setIsLoading(false);
    } else {
      newErrorForm["generalError"] = true;

      newErrorForm["generalErrorMessage"] =
        "Corriger ou compléter les champs en rouge";
    }

    setErrorForm(newErrorForm);
  };

  const resetValues = () => {
    setErrorForm(defaultErrorForm);
  };

  useEffect(() => {
    if (loginSubscribeState?.openModal === "none") {
      resetValues();
    }
  }, [loginSubscribeState.openModal]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"fr-FR"}>
      <div className="py-12 space-y-4">
        <form className="space-y-8" onSubmit={validate}>
          <div className="center">
            <h2 className="font-bold center xs:text-[28px] leading-10 flex-wrap">
              {loginSubscribeState.updateAccountInfos
                ? "Mes informations"
                : "Mon compte"}{" "}
              <span className="pl-2 text-main/main-100">
                {!loginSubscribeState.updateAccountInfos ? "hôte" : ""}
              </span>
            </h2>
          </div>

          <div className="center">
            <div className="w-[320px] md:w-[340px] space-y-4">
              <div className="center">
                <TextField
                  error={errorForm.companyNameError}
                  value={loginSubscribeState.companyName}
                  label="Nom de la société"
                  onChange={(event) => handleChange(event, "OWNER NAME")}
                  fullWidth
                  InputProps={{
                    type: "text",
                  }}
                />
              </div>

              <div className="center">
                <TextField
                  error={errorForm.personInChargeNameError}
                  value={loginSubscribeState.personInChargeName}
                  label="Nom de la personne en charge"
                  onChange={(event) =>
                    handleChange(event, "PERSON IN CHARGE NAME")
                  }
                  fullWidth
                  InputProps={{
                    type: "text",
                  }}
                  inputProps={{
                    autoComplete: "name",
                  }}
                />
              </div>

              <div className="center">
                <TextField
                  error={errorForm.rcsSirenSiretError}
                  value={loginSubscribeState.rcsSirenSiret}
                  label="RCS/Siren/Siret"
                  fullWidth
                  onChange={(event) => handleChange(event, "RCS SIREN SIRET")}
                  InputProps={{
                    type: "number",
                  }}
                />
              </div>

              <div className="center">
                <DatePicker
                  label={"Date de naissance"}
                  maxDate={maxDate}
                  minDate={minDate}
                  defaultValue={
                    loginSubscribeState.updateAccountInfos
                      ? dayjs(new Date(loginSubscribeState.birthDate))
                      : null
                  }
                  onChange={(event) => {
                    loginSubscribeDispatch({
                      type: "SET",
                      kind: "BIRTH DATE",
                      target: event ? event["$d"] : "",
                    });
                  }}
                  sx={{
                    width: "100%",
                  }}
                  slots={{
                    openPickerIcon: () => {
                      return (
                        <IonIcon
                          icon={calendarOutline}
                          className="w-6 h-6 text-black p-[1px]"
                        ></IonIcon>
                      );
                    },
                  }}
                  slotProps={{
                    textField: {
                      error: errorForm.birthDateError,
                    },
                  }}
                  format="DD-MM-YYYY"
                  inputProps={{
                    autoComplete: "bday",
                  }}
                />
              </div>

              <div className="center">
                <TextField
                  error={errorForm.phoneNumberError}
                  value={loginSubscribeState.phoneNumber}
                  label="Téléphone"
                  fullWidth
                  onChange={(event) => handleChange(event, "PHONE NUMBER")}
                  InputProps={{
                    type: "tel",
                  }}
                  inputProps={{
                    autoComplete: "tel",
                  }}
                />
              </div>

              <div className="center grid grid-cols-2 space-x-2 ">
                <TextField
                  error={errorForm.streetNumberError}
                  value={loginSubscribeState.streetNumber}
                  label="N°"
                  sx={{
                    width: "100px",
                  }}
                  onChange={(event) => handleChange(event, "STREET NUMBER")}
                  InputProps={{
                    type: "text",
                  }}
                  inputProps={{
                    autoComplete: "address-line1",
                  }}
                />

                <TextField
                  error={errorForm.streetError}
                  value={loginSubscribeState.street}
                  label="Rue"
                  onChange={(event) => handleChange(event, "STREET")}
                  fullWidth
                  autoComplete="street-address"
                  InputProps={{
                    type: "text",
                  }}
                  inputProps={{
                    autoComplete: "address-line1",
                  }}
                />
              </div>

              <div className="center">
                <TextField
                  value={loginSubscribeState.addressComplement}
                  label="Complément d'adresse (facultatif)"
                  onChange={(event) =>
                    handleChange(event, "ADDRESS COMPLEMENT")
                  }
                  fullWidth
                  autoComplete="address-level4"
                  InputProps={{
                    type: "text",
                    "aria-labelledby": "input-slider",
                  }}
                  inputProps={{
                    autoComplete: "address-line2",
                  }}
                />
              </div>

              <div className="center grid grid-cols-2 space-x-2">
                <TextField
                  error={errorForm.postalCodeError}
                  value={loginSubscribeState.postalCode}
                  label="Code postal"
                  sx={{
                    width: "40%",
                  }}
                  onChange={(event) => handleChange(event, "POSTAL CODE")}
                  InputProps={{
                    type: "number",
                  }}
                  inputProps={{
                    autoComplete: "postal-code",
                  }}
                />

                <TextField
                  error={errorForm.cityError}
                  value={loginSubscribeState.city}
                  label="Ville"
                  sx={{
                    width: "60%",
                  }}
                  onChange={(event) => handleChange(event, "CITY")}
                  fullWidth
                  InputProps={{
                    type: "text",
                  }}
                  inputProps={{
                    autoComplete: "address-level1",
                  }}
                />
              </div>

              <SelectCountry
                error={errorForm.countryError}
                handleChange={(event) => {
                  handleChange(event, "COUNTRY");
                }}
                state={loginSubscribeState}
              />
            </div>
          </div>

          {isLoading ? (
            <div className="flex w-full justify-center">
              <CircularProgress size={40} thickness={4} />
            </div>
          ) : (
            <div className="flex flex-col items-center space-y-3">
              <button
                type="submit"
                disabled={buttonDisabled}
                className="purple-button"
              >
                {loginSubscribeState.updateAccountInfos
                  ? "Enregistrer"
                  : "CR" + "\u00c9" + "ER UN COMPTE"}
              </button>

              {errorForm?.generalError && (
                <p className="block text-xs font-inter text-semantic/error">
                  {errorForm?.generalErrorMessage}
                </p>
              )}
            </div>
          )}
        </form>
      </div>
    </LocalizationProvider>
  );
};

const ProfessionalHostAccountDetails = () => {
  const { loginSubscribeState } = useAuth();

  return (
    <AuthModal
      children={ProfessionalHostAccountDetailsContent()}
      isOpen={loginSubscribeState.openModal == "ProfessionalHostAccountDetails"}
      width="480px"
      height="960px"
      withHeader={!loginSubscribeState?.updateAccountInfos}
    />
  );
};
export default ProfessionalHostAccountDetails;
