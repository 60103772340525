import React, { useEffect, useState, useRef } from "react";
import { IonIcon } from "@ionic/react";
import { calendarOutline } from "ionicons/icons";
import { fr } from "date-fns/locale";
import { DayPicker as Calendar } from "react-day-picker";
import { addYears, format, endOfMonth } from "date-fns";
import { useLocation } from "react-router-dom";

const DatePicker = ({ handleDatesSelected, isDayBlocked, selected }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const isSearchPage = location.pathname === "/search";
  const wrapperRef = useRef(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const currentDate = new Date();
  const minDate = currentDate;
  const maxDate = endOfMonth(addYears(currentDate, 2));
  const [defaultMonth, setDefaultMonth] = useState(minDate);
  const disabled = isDayBlocked && isDayBlocked();

  const handleCalendarState = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  useEffect(() => {
    if (selected?.to) {
      setDefaultMonth(selected?.from);
      setIsCalendarOpen(false);
    }
  }, [selected]);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsCalendarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="relative w-full " ref={wrapperRef}>
        <div
          className={`flex w-full items-center px-4 py-3 space-x-2 bg-white cursor-pointer border ${
            isCalendarOpen && (isHomePage || isSearchPage)
              ? "border-main/main-100"
              : "border-neutral/gray/gray-40"
          }`}
          onClick={handleCalendarState}
        >
          <div className="flex w-full">
            <p className="text-sm line-clamp-1">
              {selected?.from && selected?.to
                ? format(new Date(selected?.from), "dd MMMM") +
                  " - " +
                  format(new Date(selected?.to), "dd MMMM")
                : "Dates"}
            </p>
          </div>

          <IonIcon
            icon={calendarOutline}
            className={`w-6 h-6 flex-none ${
              isHomePage || isSearchPage
                ? "text-neutral/gray/gray-60"
                : "text-main/dark-100"
            }`}
          />
        </div>

        {isCalendarOpen && (
          <div className="absolute top-16 left-0 z-30 bg-white rounded-[4px] border border-neutral/gray/gray-40">
            <Calendar
              id="dataRangPicker"
              mode="range"
              disabled={disabled}
              initialFocus={isCalendarOpen}
              selected={selected}
              onSelect={handleDatesSelected}
              fromDate={minDate}
              toDate={maxDate}
              locale={fr}
              defaultMonth={defaultMonth}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DatePicker;
