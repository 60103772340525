import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { arrowForward, arrowBack } from "ionicons/icons";
import MobileStepper from "@mui/material/MobileStepper";
import useAuth from "../../hooks/useAuth";
import useAd from "../../hooks/useAd";
import useBooking from "../../hooks/useBooking";

const Actualities = ({ adsNotCompleted }) => {
  const [actuPageNumber, setActuPageNumber] = useState(1);
  const [actu, setActu] = useState([]);
  const { currentUser } = useAuth();
  const { setActiveStep, setCompleteAd, completeAd } = useAd();
  const { pendingBookings, futureBookings } = useBooking();
  const navigate = useNavigate();

  const addActuAds = async () => {
    return adsNotCompleted?.map((el) => {
      return {
        name: "ad",
        propertyName: el.title,
        descriptionCallToAction:
          "Ajoutez vos conditions d'annulation, vos services proposés, si vous acceptez des animaux...",

        status: "adToBeVerified",
        actionName: "Ajouter",
        image: el.picturesUrls[0],
        action: () => {
          const completeAdData = window.localStorage.getItem(
            `COMPLETE_AD_STATE_${el?.uid}`
          );
          if (completeAdData !== null) {
            setCompleteAd({
              ...JSON.parse(completeAdData),
              uid: el.uid,
              title: el.title,
            });
          } else {
            setCompleteAd({ ...completeAd, uid: el.uid, title: el.title });
          }

          setActiveStep(9);
        },
      };
    });
  };

  const addActuBookings = async (bookings, status) => {
    return bookings?.map(({ booking, property, user }) => {
      return {
        propertyName: property?.title,
        titleVerified: `Nouvelle réservation de ${user?.firstName} ${user?.lastName} pour `,
        titleCallToAction: "Réservation en attente de validation pour ",
        descriptionCallToAction: "Expire dans 24h",
        status: status,
        actionName: "Voir la réservation",
        action: () => {
          navigate(`/bookings#${booking.uid}`);
        },
      };
    });
  };

  const updateActu = async () => {
    const actuAds = await addActuAds();

    const actuPendingBookings = await addActuBookings(
      pendingBookings,
      "bookingPending"
    );

    const actuConfirmedBookings = await addActuBookings(
      futureBookings,
      "newBooking"
    );
    setActu(
      actuPendingBookings?.concat(actuConfirmedBookings?.concat(actuAds))
    );

    //setActu(actuAds);
  };

  useEffect(() => {
    if (currentUser) {
      updateActu();
    }
  }, [currentUser, futureBookings, pendingBookings]);

  let numberOfActuPerPage = 4;
  if (window.innerWidth < 1024) {
    numberOfActuPerPage = 2;
  }

  if (actu?.length > 0) {
    return (
      <div className="flex flex-col pt-[35px] pb-[20px] lg:pt-[70px]">
        <h2 className="text-[25px] lg:text-[32px] ">Mes actualités</h2>

        <div className="">
          {actu?.map((field, index) => {
            if (
              (actuPageNumber - 1) * numberOfActuPerPage <= index &&
              index < actuPageNumber * numberOfActuPerPage
            ) {
              return (
                <div className="" key={index}>
                  <Actuality field={field} />
                </div>
              );
            } else {
              return "";
            }
          })}

          <div className={`grid grid-cols-3 items-center gap-2`}>
            <div className="">
              {actuPageNumber > 1 && (
                <button
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    setActuPageNumber(actuPageNumber - 1);
                  }}
                >
                  <IonIcon
                    icon={arrowBack}
                    className="w-7 h-7 text-main/main-100 lg:pr-2 "
                  ></IonIcon>

                  <div className="hidden lg:block">Précédent</div>
                </button>
              )}
            </div>

            <div className="center">
              <MobileStepper
                variant="dots"
                steps={Math.ceil(actu?.length / numberOfActuPerPage)}
                position="static"
                activeStep={actuPageNumber - 1}
                sx={{
                  maxWidth: 400,
                  backgroundColor: "transparent",

                  "& .MuiMobileStepper-dot": {
                    backgroundColor: "white",
                    borderColor: "var(--first-color)",
                    borderWidth: "0.1px",
                    width: "7px",
                    height: "7px",
                    margin: "2px",
                  },
                  "& .MuiMobileStepper-dotActive": {
                    backgroundColor: "var(--first-color)",
                    width: "10px",
                    height: "10px",
                    margin: "4px",
                    marginTop: "0px",
                  },
                }}
              />
            </div>

            <div className="flex justify-end">
              {actuPageNumber * numberOfActuPerPage < actu?.length && (
                <button
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    setActuPageNumber(actuPageNumber + 1);
                  }}
                >
                  <div className="hidden lg:block">Suivant</div>
                  <IonIcon
                    icon={arrowForward}
                    className="w-7 h-7 text-main/main-100 pl-2"
                  ></IonIcon>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return;
  }
};

const Actuality = ({ field }) => {
  const {
    propertyName,
    titleVerified,
    titleCallToAction,
    descriptionCallToAction,
    status,
    actionName,
    image,
    action,
  } = field;

  const ActualityTemplate = ({ picture, text, button }) => {
    return (
      <div className="py-4 pr-4  my-3 lg:py-4 lg:my-5 rounded-[16px] lg:rounded-[116px]  bg-neutral/gray/gray-10 ">
        <div className="flex flex-col md:flex-row  space-x-6 lg:justify-between gap-2">
          <div className="hidden w-[180px] md:flex ">{picture}</div>

          <div className=" flex gap-4  md:grow">
            <div className=" md:hidden">{picture}</div>
            <div>{text}</div>
          </div>

          <div className=" pb-2 lg:pb-0 flex items-center justify-center   ">
            {button}
          </div>
        </div>
      </div>
    );
  };

  const Booking = () => {
    const Picture = () => {
      return (
        <div className="flex items-center  md:pl-5 ">
          {status == "bookingPending" ? (
            <div className="w-[40px] h-[40px]  rounded-full lg:w-[148px] lg:h-[57px] !bg-[#8954FA] !bg-opacity-10 lg:flex lg:justify-center items-center  ">
              <img
                className="w-[40px] h-[40px] p-1  "
                src="/images/navigation-signs/fire.svg"
                alt="green checked mark circle"
              />
            </div>
          ) : (
            <div className="w-[40px] h-[40px] ">
              <img
                className="w-[30px] h-[30px] lg:ml-[60px]"
                src="/images/navigation-signs/green-checked-mark-circle.svg"
                alt="green checked mark circle"
              />
            </div>
          )}
        </div>
      );
    };
    const Text = () => {
      return (
        <div className="space-y-2 lg:space-y-0 flex  flex-col  ">
          <p className="text-[16px] lg:text-xl font-semibold lg:font-bold ">
            {status == "bookingPending" ? titleCallToAction : titleVerified}{" "}
            <span className="text-main/main-100">{propertyName}</span>
            {status == "newBooking" && " !"}
          </p>

          {status == "bookingPending" && (
            <p className=" text-neutral/gray/gray-100  lg:-mt-1 text-[16px] line-clamp-3">
              {descriptionCallToAction}
            </p>
          )}
        </div>
      );
    };
    const Button = () => {
      return (
        <button
          className="secondary-purple-button transition-none  !h-[38px] !w-[190px] lg:!h-[48px] lg:!w-[236px] center !text-xs lg:!text-[14px] !font-medium "
          onClick={action}
        >
          {actionName}
        </button>
      );
    };
    return (
      <ActualityTemplate
        picture={<Picture />}
        text={<Text />}
        button={<Button />}
      />
    );
  };

  const AdToBeVerified = () => {
    const Picture = () => {
      return (
        <div className="flex items-center  md:pl-5 w-[40px] h-[40px] md:w-full md:h-full ">
          <img
            className="w-[40px] h-[40px] rounded-full lg:w-[148px] lg:h-[57px] object-cover"
            src={image}
            alt="picture of the house"
          />
        </div>
      );
    };
    const Text = () => {
      return (
        <div className="col-span-4  space-y-2 lg:space-y-0 lg:items-center  flex flex-col lg:grid lg:grid-rows-2 ">
          <p className="text-[16px] lg:text-xl font-semibold lg:font-bold">
            Publiez votre annonce{" "}
            <span className="text-main/main-100">{propertyName}</span> en{" "}
            <span className="text-main/main-100">7 étapes</span>{" "}
          </p>

          <p className=" text-neutral/gray/gray-100 -mt-1 text-[16px] line-clamp-3">
            {descriptionCallToAction}
          </p>
        </div>
      );
    };
    const Button = () => {
      return (
        <button
          className="secondary-purple-button transition-none  !h-[38px] !w-[99px] lg:!h-[48px] lg:!w-[134px] center !text-xs  lg:!text-[14px] !font-medium "
          onClick={action}
        >
          {actionName}
        </button>
      );
    };

    return (
      <ActualityTemplate
        picture={<Picture />}
        text={<Text />}
        button={<Button />}
      />
    );
  };

  if (status == "adToBeVerified") {
    return <AdToBeVerified />;
  }
  if (status == "bookingPending" || status == "newBooking") {
    return <Booking />;
  }
};

export default Actualities;
