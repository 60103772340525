import React from "react";
import { IonIcon } from "@ionic/react";
import { homeOutline, keyOutline, personOutline } from "ionicons/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const MobileNavBar = () => {
  const { currentUser, loginSubscribeDispatch } = useAuth();
  const isTraveler = currentUser?.userType === "traveler";
  const location = useLocation();
  const navigate = useNavigate();

  const MenuItem = ({ to, picture, title, pictureGrey, picturePurple }) => {
    const navigateTo = () => {
      if (to === "/login") {
        return loginSubscribeDispatch({
          type: "GO TO",
          to: "LoginOrSubscribe",
        });
      } else {
        return navigate(to);
      }
    };

    return (
      <div className="flex justify-center">
        <button
          onClick={navigateTo}
          className={`flex flex-col items-center space-y-1 ${
            location.pathname === to
              ? "text-main/main-100"
              : "text-neutral/gray/gray-100"
          }`}
        >
          {picture}
          {location.pathname === to ? picturePurple : pictureGrey}
          <p className="text-[10px] leading-[150%] font-bold font-inter">
            {title}
          </p>
        </button>
      </div>
    );
  };

  return (
    <div
      className="lg:hidden fixed bottom-0 right-0 left-0 flex items-center  md:px-10 z-50 p-4 rounded-t-[32px] bg-white shadow-[0px_0px_10px_0px_#EBE7F3]"
      id="mobile-navbar"
    >
      <nav
        className={`w-full ${
          currentUser && isTraveler
            ? "grid grid-cols-3"
            : currentUser && !isTraveler
            ? "grid grid-cols-4"
            : "grid grid-cols-2"
        }
          `}
      >
        <MenuItem
          to={currentUser && !isTraveler ? "/dashboard" : "/"}
          picture={
            <IonIcon icon={homeOutline} className="w-[27px] h-[27px]"></IonIcon>
          }
          title={currentUser && !isTraveler ? "Tableau de bord" : "Accueil"}
        />

        {currentUser && (
          <MenuItem
            to="/bookings"
            picture={
              <IonIcon
                icon={keyOutline}
                className="w-[27px] h-[27px]"
              ></IonIcon>
            }
            title={isTraveler ? "Mes séjours" : "Réservations"}
          />
        )}

        {currentUser && !isTraveler && (
          <MenuItem
            to="/properties"
            pictureGrey={
              <img
                src="/images/navigation-signs/ads-grey.svg"
                className="w-[26px] h-[26px] "
              />
            }
            picturePurple={
              <img
                src="/images/navigation-signs/ads-purple.svg"
                className="w-[26px] h-[26px] "
              />
            }
            title="Annonces"
          />
        )}

        <MenuItem
          to={currentUser ? "/profile" : "/login"}
          picture={
            <IonIcon
              icon={personOutline}
              className="w-[27px] h-[27px]"
            ></IonIcon>
          }
          title="Profil"
        />
      </nav>
    </div>
  );
};

export default MobileNavBar;
