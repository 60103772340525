import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAd from "../../hooks/useAd";
const ToggleInstantBooking = ({
  firstValue,
  secondValue,
  disabled = false,
}) => {
  const { editAd, setEditAd, updatePropertyFromDatabase } = useAd();
  const { propertyUid } = useParams();
  const [selectValue, setSelectValue] = useState("Oui");
  const handleInstantBooking = async () => {
    await updatePropertyFromDatabase(propertyUid, {
      instantBooking: !editAd?.instantBooking,
    });
    setEditAd({ ...editAd, instantBooking: !editAd?.instantBooking });
  };
  function handleSetSelectValue() {
    setSelectValue(
      !editAd.hasOwnProperty("instantBooking") || editAd?.instantBooking
        ? "Oui"
        : "Non"
    );
  }
  useEffect(() => {
    handleSetSelectValue();
  }, [editAd]);
  return (
    <div className="flex space-x-2">
      <p
        className={`${
          selectValue === secondValue && "text-neutral/gray/gray-100"
        }`}
      >
        {firstValue}
      </p>

      <div
        className={`flex rounded-full ${
          disabled ? "bg-neutral/gray/gray-60" : "bg-main/main-100"
        } w-12 h-6 items-center px-1 cursor-pointer ${
          selectValue === firstValue ? "justify-start" : "justify-end"
        }`}
        onClick={handleInstantBooking}
      >
        <div className="bg-white w-4 h-4 rounded-full"></div>
      </div>

      <p
        className={`${
          selectValue === firstValue && "text-neutral/gray/gray-100"
        }`}
      >
        {secondValue}
      </p>
    </div>
  );
};
export default ToggleInstantBooking;
