import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthModal from "./AuthModal";
import { InputAdornment, TextField } from "@mui/material";
import { IonIcon } from "@ionic/react";
import { mailOutline, eyeOffOutline, eyeOutline } from "ionicons/icons";
import * as validator from "validator";
import useAuth from "../../hooks/useAuth";

const LoginContent = () => {
  const navigate = useNavigate();
  let defaultErrorForm = {
    emailError: false,
    emailErrorMessage: "",
    passwordError: false,
    passwordErrorMessage: "",
    generalError: false,
    generalErrorMessage: "",
  };
  const {
    LoginWithEmailAndPassword,
    loginSubscribeState,
    loginSubscribeDispatch,
    updateImportedCalendarsOfAHost,
    currentUser,
  } = useAuth();
  const buttonDisabled =
    loginSubscribeState.email === "" && loginSubscribeState.password === ""
      ? true
      : false;
  const [errorForm, setErrorForm] = useState(defaultErrorForm);
  const [showPassword, setShowPassword] = useState(false);
  const [connected, setConnected] = useState(false);

  const handleChange = (event, kind) => {
    let target = event.target.value;
    loginSubscribeDispatch({ type: "SET", kind: kind, target: target });
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const validate = async () => {
    let newErrorForm = defaultErrorForm;

    let passwordIsValid = validator.isStrongPassword(
      loginSubscribeState.password,
      {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      }
    );

    if (!validator.isEmail(loginSubscribeState.email)) {
      newErrorForm["emailError"] = true;
      newErrorForm["emailErrorMessage"] = "L'adresse e-mail n'est pas valide.";
    }

    if (!passwordIsValid) {
      newErrorForm["passwordError"] = true;
      newErrorForm["passwordErrorMessage"] =
        "Le mot de passe doit inclure au moins 8 caractères, 1 chiffre, 1 minuscule et 1 majuscule.";
    }

    let validate = !newErrorForm.emailError && !newErrorForm.passwordError;

    if (validate) {
      const res = await LoginWithEmailAndPassword(
        loginSubscribeState.email,
        loginSubscribeState.password
      );

      if (res?.statusCode === 200) {
        setConnected(true);

        loginSubscribeDispatch({ type: "CLOSE" });
      } else {
        newErrorForm["generalError"] = true;
        newErrorForm["generalErrorMessage"] = res?.errorMessage;
      }
    } else {
      newErrorForm["generalError"] = true;
      newErrorForm["generalErrorMessage"] =
        "Corriger ou compléter les champs en rouge";
    }

    setErrorForm(newErrorForm);
  };

  useEffect(() => {
    const navigateTo = () => {
      if (!loginSubscribeState.updateAccountInfos) {
        navigate("/dashboard");
      }
    };

    if (currentUser?.userType === "host" && connected) {
      updateImportedCalendarsOfAHost(currentUser?.uid);
      navigateTo();
    }
  }, [currentUser]);

  useEffect(() => {
    const resetValues = () => {
      setErrorForm(defaultErrorForm);
    };

    if (loginSubscribeState?.openModal === "none") {
      resetValues();
    }
  }, [loginSubscribeState.openModal]);

  return (
    <div className="flex flex-col justify-center h-full space-y-32 md:space-y-14">
      <div className="space-y-[60px] px-0 md:px-10">
        <h2 className="text-center">Se connecter</h2>

        <div className="space-y-8">
          <div className="space-y-4">
            <div className="center">
              <TextField
                error={errorForm.emailError}
                helperText={errorForm.emailErrorMessage}
                value={loginSubscribeState.email}
                label="Email"
                autoComplete="email"
                onChange={(event) => handleChange(event, "EMAIL")}
                sx={{
                  "& .MuiFormHelperText-root": {
                    color: "var(--error-color)",
                    margin: "1px",
                  },
                }}
                fullWidth
                InputProps={{
                  type: "text",
                  "aria-labelledby": "input-slider",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IonIcon
                        icon={mailOutline}
                        className="w-6 h-6 text-main/dark-100 p-[1px]"
                      ></IonIcon>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="space-y-2">
              <div className="center">
                <TextField
                  error={errorForm.passwordError}
                  helperText={errorForm.passwordErrorMessage}
                  value={loginSubscribeState.password}
                  label="Mot de passe"
                  onChange={(event) => handleChange(event, "PASSWORD")}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      color: "var(--error-color)",
                      margin: "1px",
                    },
                  }}
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    "aria-labelledby": "input-slider",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IonIcon
                          icon={showPassword ? eyeOutline : eyeOffOutline}
                          className="w-6 h-6 text-main/dark-100 p-[1px] cursor-pointer"
                          onClick={handleClickShowPassword}
                        ></IonIcon>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <button
                className="text-xs underline text-design-system/highlight"
                onClick={() =>
                  loginSubscribeDispatch({ type: "GO TO", to: "ResetPassword" })
                }
              >
                Mot de passe oublié ?
              </button>
            </div>
          </div>

          <div className="flex flex-col items-center space-y-3">
            <button
              disabled={buttonDisabled}
              className="purple-button"
              onClick={validate}
            >
              SE CONNECTER
            </button>

            {errorForm.generalError && (
              <p className="block text-xs font-inter text-semantic/error">
                {errorForm.generalErrorMessage}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Login = () => {
  const { loginSubscribeState } = useAuth();

  return (
    <AuthModal
      children={LoginContent()}
      isOpen={loginSubscribeState.openModal === "Login"}
    />
  );
};
export default Login;
