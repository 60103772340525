import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
  useReducer,
} from "react";
import { auth } from "../firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { API } from "aws-amplify";
import { BUILD_ENV } from "../App";
import {
  defaultState as loginSubscribeDefaultState,
  reducer as loginSubscribeReducer,
} from "../components/login-subscribe/Reducer";
import { notify_url, hostname } from "../App";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const host = window.location.origin;
  const [currentUser, setCurrentUser] = useState(null);
  const [timeActive, setTimeActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loginSubscribeState, loginSubscribeDispatch] = useReducer(
    loginSubscribeReducer,
    loginSubscribeDefaultState
  );

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const data = await getUserInDatabase(user.uid);

        if (data) {
          setCurrentUser(data);
        }
      } else {
        setCurrentUser(null);
      }

      setIsLoading(false);
    });
  }, [auth?.currentUser]);

  useEffect(() => {
    if (
      auth?.currentUser?.emailVerified &&
      currentUser?.emailVerified === false
    ) {
      UserReload();
    }
  }, [auth?.currentUser?.emailVerified]);

  useEffect(() => {
    if (
      ["", "LoginOrSubscribe", "AccountWellCreated"].includes(
        loginSubscribeState.openModal
      )
    ) {
      window.localStorage.setItem(
        "CREATE_AN_ACCOUNT_STATE",
        JSON.stringify({ ...loginSubscribeState, password: "" })
      );
    }
  }, [loginSubscribeState]);

  /*-------------------------------------------------Register-----------------------------------------------------*/
  const RegisterWithEmailAndPassword = async (params) => {
    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        params.email,
        params.password
      );

      if (res) {
        delete params.password;
        params["uid"] = res.user.uid;
        params["emailVerified"] = false;

        await addUserInDatabase(params);
      }
      return { statusCode: 200 };
    } catch (error) {
      const errorCode = error.code;
      let errorMessage;

      if (errorCode === "auth/email-already-in-use") {
        errorMessage = "L'adresse email est déjà utilisée";
      } else {
        errorMessage =
          "Une erreur s'est produite veuillez réessayer plus tard.";
        console.log(error);
      }

      return { statusCode: 400, errorMessage: errorMessage };
    }
  };

  /*-------------------------------------------------Login-----------------------------------------------------*/
  const LoginWithEmailAndPassword = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);

      return { statusCode: 200 };
    } catch (error) {
      const errorCode = error.code;
      let errorMessage;

      if (errorCode === "auth/wrong-password") {
        errorMessage = "Le mot de passe est incorrect.";
      } else if (errorCode === "auth/user-not-found") {
        errorMessage = "L'utilisateur est introuvable.";
      } else {
        errorMessage =
          "Une erreur s'est produite veuillez réessayer plus tard.";
        console.log(errorCode);
      }

      return { statusCode: 400, errorMessage: errorMessage };
    }
  };

  /*-------------------------------------------------Verification By Email-----------------------------------------------------*/
  const sendEmailForVerification = () => {
    sendEmailVerification(auth.currentUser)
      .then(() => {
        setTimeActive(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*-------------------------------------------------Email Check-----------------------------------------------------*/
  const UserReload = () => {
    return auth.currentUser
      ?.reload()
      .then(async () => {
        if (auth?.currentUser?.emailVerified && !currentUser?.emailVerified) {
          const res = await UpdateUserInDatabase(currentUser?.uid, {
            emailVerified: true,
          });
          setCurrentUser({ ...currentUser, emailVerified: true });

          if (res.status === 200) {
            setTimeActive(false);
          }

          if (currentUser?.userType === "host") {
            await addMerchant(currentUser);
          }

          return { statusCode: 200 };
        }

        return { statusCode: 202 };
      })
      .catch((error) => {
        console.log(error);
        return { statusCode: 400, errorMessage: error };
      });
  };

  /*-------------------------------------------------Password Reset-----------------------------------------------------*/
  const PasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);

      return { statusCode: 200 };
    } catch (error) {
      console.log(error);

      let errorMessage =
        "Une erreur s'est produite veuillez réessayer plus tard.";
      return { statusCode: 400, errorMessage: errorMessage };
    }
  };

  /*-------------------------------------------------Logout-----------------------------------------------------*/
  const Logout = async () => {
    signOut(auth)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  /*-------------------------------------------------Check User By Email-----------------------------------------------------*/
  const checkUserByEmail = async (email) => {
    try {
      const apiName = "usersManager";
      const path = `/users/email/${email}`;

      let data;

      if (BUILD_ENV === "localhost") {
        data = await fetch(`http://${hostname}:3002${path}`);
        data = await data.json();
      } else {
        data = await API.get(apiName, path);
      }

      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  /*-------------------------------------------------add User In Database-----------------------------------------------------*/
  const addUserInDatabase = async (params) => {
    try {
      const userUid = params.uid;
      const apiName = "usersManager";
      const path = `/users/${userUid}`;

      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      let data;
      if (BUILD_ENV === "localhost") {
        options["method"] = "POST";
        options["body"] = JSON.stringify(params);

        data = await fetch(`http://${hostname}:3002${path}`, options);
      } else {
        options["body"] = params;
        data = await API.post(apiName, path, options);
      }

      if (data) {
        await addUserWallet(params.uid);
        sendEmailForVerification();
      }

      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  /*-------------------------------------------------add User Wallet-----------------------------------------------------*/
  const addUserWallet = async (userUid) => {
    try {
      const apiName = "web3Manager";
      const path = `/newWallet`;
      let options = {
        queryStringParameters: {
          userUid: userUid,
        },
      };

      let data;
      if (BUILD_ENV === "localhost") {
        data = await fetch(
          `http://${hostname}:3004${path}?userUid=${userUid}`,
          { method: "POST" }
        );
        data = await data.json();
      } else {
        data = await API.post(apiName, path, options);
      }

      if (data.msg) {
        console.log(data.msg);
      }

      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  /*-------------------------------------------------Update User In Database-----------------------------------------------------*/
  const UpdateUserInDatabase = async (userUid, params) => {
    try {
      const apiName = "usersManager";
      const path = `/users/${userUid}`;

      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let data;

      if (BUILD_ENV === "localhost") {
        options["method"] = "PUT";
        options["body"] = JSON.stringify(params);

        data = await fetch(`http://${hostname}:3002${path}`, options);
      } else {
        options["body"] = params;
        data = await API.put(apiName, path, options);
      }

      if (data.status === 200) {
        setCurrentUser({ ...currentUser, ...params });
        return data;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  /*-------------------------------------------------Update Pseudo Of User -----------------------------------------------------*/
  const UpdateUserPseudo = async (userUid, pseudo) => {
    try {
      const apiName = "usersManager";
      const path = `/users/pseudo/${userUid}`;
      let params = { pseudo: pseudo };
      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let data;

      if (BUILD_ENV === "localhost") {
        options["method"] = "PUT";
        options["body"] = JSON.stringify(params);

        data = await fetch(`http://${hostname}:3002${path}`, options);
        data = await data.json();
      } else {
        options["body"] = params;
        data = await API.put(apiName, path, options);
      }

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  /*-------------------------------------------------Get User In Database-----------------------------------------------------*/
  const getUserInDatabase = async (userUid) => {
    try {
      const apiName = "usersManager";
      const path = `/users/${userUid}`;
      let data;

      if (BUILD_ENV === "localhost") {
        data = await fetch(`http://${hostname}:3002${path}`);
        data = await data.json();
      } else {
        data = await API.get(apiName, path);
      }

      if (data?.uid) {
        return data;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  /*-------------------------------------------------Get User by pseudo In Database-----------------------------------------------------*/
  const getUserByPseudo = async (pseudo) => {
    try {
      const apiName = "usersManager";
      const path = `/users/pseudo/${pseudo}`;
      let data;

      if (BUILD_ENV === "localhost") {
        data = await fetch(`http://${hostname}:3002${path}`);
        data = await data.json();
      } else {
        data = await API.get(apiName, path);
      }

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  /*-------------------------------------------------Get available pseudo In Database-----------------------------------------------------*/
  const getAvailablePseudo = async (pseudo) => {
    try {
      const apiName = "usersManager";
      const path = `/users/findPseudo/${pseudo}`;
      let data;

      if (BUILD_ENV === "localhost") {
        data = await fetch(`http://${hostname}:3002${path}`);
        data = await data.json();
      } else {
        data = await API.get(apiName, path);
      }

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  /*-------------------------------------------------Get User Data-----------------------------------------------------*/
  const getUserInfo = async (userUid) => {
    try {
      const apiName = "usersManager";
      const path = `/users/${userUid}`;
      let data;

      if (BUILD_ENV === "localhost") {
        data = await fetch(`http://${hostname}:3002${path}`);
        data = await data.json();
      } else {
        data = await API.get(apiName, path);
      }

      if (data) {
        return {
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
          email: data.email,
          profilepicture: data.profilePicture,
        };
      } else {
        throw new Error("No matching user.");
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  /*-------------------------------------------------Add Merchant In OPP -----------------------------------------------------*/
  const addMerchant = async (currentUser) => {
    try {
      const isProfessional = currentUser?.isProfessional;

      let params = {
        country: "fra",
        locale: "fr",
        legal_name: "",
        emailaddress: currentUser?.email,
        addresses: [
          {
            address_line_1:
              currentUser?.street + " " + currentUser?.streetNumber,
            address_line_2: currentUser?.addressComplement,
            zipcode: currentUser?.postalCode,
            city: currentUser?.city,
            country: "fra",
          },
        ],
        phone: currentUser?.phoneNumber,
        metadata: {
          userUid: currentUser?.uid,
        },
        notify_url: notify_url,
        return_url: `${host}/profile`,
      };

      if (isProfessional) {
        params["coc_nr"] = currentUser?.rcsSirenSiret;
        params["type"] = "business";
      } else {
        params["name_first"] = currentUser?.firstName;
        params["name_last"] = currentUser?.lastName;
        params["type"] = "consumer";
      }

      const userUid = currentUser.uid;
      const apiName = "usersManager";
      const path = `/merchants`;

      let options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      let data;

      if (BUILD_ENV === "localhost") {
        options["method"] = "POST";
        options["body"] = JSON.stringify(params);

        data = await fetch(`http://${hostname}:3002${path}`, options);
        data = await data.json();
      } else {
        options["body"] = params;
        data = await API.post(apiName, path, options);
      }

      if (data.uid) {
        const dataOfMerchant = {
          dataOfMerchant: data,
        };

        await UpdateUserInDatabase(userUid, dataOfMerchant);
      } else {
        throw new Error("Error when creating merchant.");
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  //---------------------------------------------udate caendars on login----------------------------------
  const updateImportedCalendarsOfAHost = async (hostUid) => {
    try {
      const apiName = "bookingsManager";
      const path = `/updateImportedCalendarsOfAHost/${hostUid}`;
      let options = {
        headers: {
          Accept: "application/json",
        },
      };
      let response;

      if (BUILD_ENV === "localhost") {
        options["method"] = "PUT";

        response = await fetch(`http://${hostname}:3003${path}`, options);
        response = await response.json();
      } else {
        response = await API.put(apiName, path, options);
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const memodValue = useMemo(
    () => ({
      currentUser,
      loginSubscribeState,
      timeActive,
      isLoading,
      setCurrentUser,
      getUserByPseudo,
      loginSubscribeDispatch,
      setIsLoading,
      setTimeActive,
      RegisterWithEmailAndPassword,
      sendEmailForVerification,
      UserReload,
      LoginWithEmailAndPassword,
      UpdateUserInDatabase,
      UpdateUserPseudo,
      getAvailablePseudo,
      Logout,
      PasswordReset,
      getUserInfo,
      checkUserByEmail,
      getUserInDatabase,
      updateImportedCalendarsOfAHost,
    }),
    [currentUser, loginSubscribeState, isLoading, timeActive]
  );
  return (
    <AuthContext.Provider value={memodValue}>{children}</AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
