import React, { useState, useEffect } from "react";
import AuthModal from "./AuthModal";
import { InputAdornment, TextField } from "@mui/material";
import { IonIcon } from "@ionic/react";
import { mailOutline, eyeOffOutline, eyeOutline } from "ionicons/icons";
import CheckboxUserConditions from "./CheckboxUserConditions";
import * as validator from "validator";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";

const AccountContent = (isProfessional) => {
  let defaultErrorForm = {
    emailError: false,
    emailErrorMessage: "",
    passwordError: false,
    passwordErrorMessage: "",
    userConditionsError: false,
  };
  const { loginSubscribeState, loginSubscribeDispatch, checkUserByEmail } =
    useAuth();
  const [errorForm, setErrorForm] = useState(defaultErrorForm);
  const [showPassword, setShowPassword] = useState(false);
  const buttonDisabled =
    loginSubscribeState.email &&
    loginSubscribeState.password &&
    loginSubscribeState.userConditions;

  const next =
    loginSubscribeState.userType === "traveler"
      ? "TravelerAccountDetails"
      : loginSubscribeState.userType === "host" && isProfessional
      ? "ProfessionalHostAccountDetails"
      : "PrivateHostAccountDetails";

  const handleChange = (event, kind) => {
    let target = event.target.value;
    loginSubscribeDispatch({ type: "SET", kind: kind, target: target });
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const validate = async (e) => {
    e.preventDefault();

    let newErrorForm = defaultErrorForm;

    let passwordIsValid = validator.isStrongPassword(
      loginSubscribeState.password,
      {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      }
    );

    if (!validator.isEmail(loginSubscribeState.email)) {
      newErrorForm["emailError"] = true;
      newErrorForm["emailErrorMessage"] = "L'adresse e-mail n'est pas valide.";
    }

    if (!passwordIsValid) {
      newErrorForm["passwordError"] = true;
      newErrorForm["passwordErrorMessage"] =
        "Le mot de passe doit inclure au moins 8 caractères, 1 chiffre, 1 minuscule et 1 majuscule.";
    }

    newErrorForm["userConditionsError"] =
      !loginSubscribeState.userConditions && true;

    let validate =
      !newErrorForm.emailError &&
      !newErrorForm.passwordError &&
      !newErrorForm.userConditionsError;

    if (validate) {
      const res = await checkUserByEmail(loginSubscribeState.email);

      if (res?.response) {
        newErrorForm["emailError"] = true;
        newErrorForm["emailErrorMessage"] =
          "L'adresse email est déjà utilisée.";
      } else {
        loginSubscribeDispatch({ type: "GO TO", to: next });
      }
    }

    setErrorForm(newErrorForm);
  };

  const resetValues = () => {
    setErrorForm(defaultErrorForm);
  };

  useEffect(() => {
    if (loginSubscribeState?.openModal === "none") {
      resetValues();
    }
  }, [loginSubscribeState.openModal]);

  return (
    <div className="flex flex-col justify-center h-full space-y-32 md:space-y-14">
      <form className="space-y-[60px] px-0 md:px-10" onSubmit={validate}>
        <h2 className="flex-wrap font-bold center xs:text-[28px]">
          Mon compte{" "}
          <span className="pl-2 text-main/main-100 ">
            {loginSubscribeState.userType === "host" ? "hôte" : "voyageur"}
          </span>
        </h2>

        <div className="space-y-8">
          <div className="space-y-4">
            <div className="center">
              <TextField
                error={errorForm.emailError}
                helperText={errorForm.emailErrorMessage}
                value={loginSubscribeState.email}
                label="Email"
                onChange={(event) => handleChange(event, "EMAIL")}
                sx={{
                  "& .MuiFormHelperText-root": {
                    color: "var(--error-color)",
                    margin: "1px",
                  },
                }}
                fullWidth
                InputProps={{
                  type: "email",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IonIcon
                        icon={mailOutline}
                        className="w-6 h-6 text-main/dark-100 p-[1px]"
                      ></IonIcon>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  autoComplete: "email",
                }}
              />
            </div>

            <div className="center">
              <TextField
                error={errorForm.passwordError}
                helperText={errorForm.passwordErrorMessage}
                value={loginSubscribeState.password}
                label="Mot de passe"
                onChange={(event) => handleChange(event, "PASSWORD")}
                sx={{
                  "& .MuiFormHelperText-root": {
                    color: "var(--error-color)",
                    margin: "1px",
                  },
                }}
                fullWidth
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IonIcon
                        icon={showPassword ? eyeOutline : eyeOffOutline}
                        className="w-6 h-6 text-main/dark-100 p-[1px] cursor-pointer"
                        onClick={handleClickShowPassword}
                      ></IonIcon>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  autoComplete: "current-password",
                }}
              />
            </div>

            <div className="">
              <CheckboxUserConditions
                userConditionsError={errorForm.userConditionsError}
                userConditionsState={loginSubscribeState.userConditions}
                loginSubscribeDispatch={loginSubscribeDispatch}
              >
                {loginSubscribeState.userType === "host" ? (
                  <span>
                    J'accepte les{" "}
                    <Link
                      className="underline"
                      to="/documents/CGVS_Hôtes.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      CGVS SlotR
                    </Link>
                    {" et "}
                    <a
                      className="underline"
                      href="https://onlinepaymentplatform.com/en/terms-policies"
                      target="_blank"
                      rel="noreferrer"
                    >
                      les termes et les conditions
                    </a>{" "}
                    de notre prestataire de paiement OPP
                  </span>
                ) : (
                  <span>
                    J'accepte les{" "}
                    <Link
                      className="underline"
                      to="/documents/CGUS_Voyageurs.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      CGUS
                    </Link>{" "}
                    de la plateforme
                  </span>
                )}
              </CheckboxUserConditions>
            </div>
          </div>

          <div className="flex justify-end space-x-2">
            <button
              type="submit"
              disabled={!buttonDisabled}
              className={`flex items-center space-x-2 !bg-transparent ${
                !buttonDisabled && "opacity-40"
              }`}
            >
              <p className="inline-block capitalize">Suivant</p>

              <img
                src="/images/purple-arrow.svg"
                alt="next arrow"
                className=" w-[24px] object-cover inline-block"
              />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export const ProfessionalHostAccount = () => {
  const { loginSubscribeState } = useAuth();

  return (
    <AuthModal
      children={AccountContent(true)}
      isOpen={loginSubscribeState.openModal == "ProfessionalHostAccount"}
    />
  );
};

export const PrivateHostAccount = () => {
  const { loginSubscribeState } = useAuth();

  return (
    <AuthModal
      children={AccountContent(false)}
      isOpen={loginSubscribeState.openModal == "PrivateHostAccount"}
    />
  );
};

export const TravelerAccount = () => {
  const { loginSubscribeState } = useAuth();

  return (
    <AuthModal
      children={AccountContent()}
      isOpen={loginSubscribeState.openModal == "TravelerAccount"}
    />
  );
};
