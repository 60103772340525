import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { BUILD_ENV, hostname } from "../App";
import { API } from "aws-amplify";
import useAuth from "../hooks/useAuth";
import { IonIcon } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";

const TransactionResponse = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { propertyUid, transactionUid } = useParams();
  const [error, setError] = useState();
  const [time, setTime] = useState(300);

  const getTransactionState = async () => {
    try {
      const apiName = "bookingsManager";
      const path = `/transactions/${transactionUid}`;
      let data;

      if (BUILD_ENV === "localhost") {
        data = await fetch(`http://${hostname}:3003${path}`);
        data = await data.json();
      } else {
        data = await API.get(apiName, path);
      }

      const transactionDate = data?.creationTime._seconds;
      const currentdate = Math.floor(Date.now() / 1000);
      const differenceInSeconds = currentdate - transactionDate;

      if (
        data &&
        currentUser?.uid === data.travelerUid &&
        differenceInSeconds <= 300
      ) {
        const transationStatus = data.status;

        if (transationStatus === "completed") {
          navigate(`/booking-summary`, {
            state: { transactionUid: transactionUid },
            isAfterPayment: true,
          });
        } else if (
          transationStatus === "cancelled" ||
          transationStatus === "failed" ||
          transationStatus === "expired"
        ) {
          setError(true);
          setTime(0);
        }
      } else {
        throw new Error("No matching transactions or deadlines expired.");
      }
    } catch (error) {
      console.log(error);
      navigate("/error");
    }
  };

  useEffect(() => {
    let interval;

    if (transactionUid && propertyUid) {
      if (time > 0 && currentUser) {
        interval = setInterval(async () => {
          setTime((time) => time - 1);
          await getTransactionState();
        }, 1000);
      } else {
        clearInterval(interval);
      }
    } else {
      navigate("/error");
    }

    return () => clearInterval(interval);
  }, [time, currentUser, transactionUid, propertyUid]);

  return (
    <div className="flex w-full grow justify-center items-center">
      {error ? (
        <div className="flex flex-col items-center">
          <IonIcon
            icon={closeCircleOutline}
            className="w-16 h-16 text-semantic/red-100"
          ></IonIcon>

          <div className="text-center space-y-1 pt-5 pb-10">
            <h1 className="text-semantic/red-100">Votre paiement a échoué</h1>

            <p>
              Votre réservation n’est pas confirmée et aucun montant n’a été
              débité.
              <br />
              Nous vous invitons à recommencer votre réservation.
            </p>
          </div>

          <Link to={`/property/${propertyUid}`}>
            <button className="purple-button uppercase">voir l’annonce</button>
          </Link>
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-12">
          <CircularProgress size={60} thickness={4} />

          <h2 className="text-Design-system/paragraph">
            {"Votre paiement est en cours de traitement"}
          </h2>
        </div>
      )}
    </div>
  );
};

export default TransactionResponse;
