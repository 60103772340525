import React, { useState, useEffect } from "react";
import AuthModal from "./AuthModal";
import { InputAdornment, TextField } from "@mui/material";
import { IonIcon } from "@ionic/react";
import { callOutline, calendarOutline } from "ionicons/icons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import SelectCountry from "./SelectCountry";
import * as validator from "validator";
import useAuth from "../../hooks/useAuth";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import CircularProgress from "@mui/material/CircularProgress";
import { toUpperCamelCase } from "./ProfessionalHostAccountDetails";

dayjs.locale("fr");

const AccountDetailsContent = () => {
  let defaultErrorForm = {
    firstNameError: false,
    lastNameError: false,
    phoneNumberError: false,
    streetNumberError: false,
    streetError: false,
    postalCodeError: false,
    cityError: false,
    countryError: false,
    generalError: false,
    generalErrorMessage: "",
  };
  const {
    RegisterWithEmailAndPassword,
    loginSubscribeState,
    loginSubscribeDispatch,
    UpdateUserInDatabase,
    getAvailablePseudo,
  } = useAuth();
  const [errorForm, setErrorForm] = useState(defaultErrorForm);
  const [isLoading, setIsLoading] = useState(false);
  const buttonDisabled =
    loginSubscribeState.firstName === "" &&
    loginSubscribeState.lastName === "" &&
    loginSubscribeState.birthDate === "" &&
    loginSubscribeState.phoneNumber === "" &&
    loginSubscribeState.street === "" &&
    loginSubscribeState.postalCode === "" &&
    loginSubscribeState.city === "" &&
    loginSubscribeState.country === ""
      ? true
      : false;

  const currentDate = dayjs();
  const minDate = currentDate.subtract(100, "year");
  const maxDate = currentDate.subtract(18, "year");

  const handleChange = (event, kind) => {
    let target = event.target.value;

    if (kind === "PHONE NUMBER" || kind === "POSTAL CODE") {
      if (target >= 0) {
        loginSubscribeDispatch({ type: "SET", kind: kind, target: target });
      }
    } else {
      loginSubscribeDispatch({ type: "SET", kind: kind, target: target });
    }
  };

  const validate = async (e) => {
    e.preventDefault();

    let newErrorForm = defaultErrorForm;

    newErrorForm["firstNameError"] =
      loginSubscribeState.firstName === "" && true;

    newErrorForm["lastNameError"] = loginSubscribeState.lastName === "" && true;

    newErrorForm["birthDateError"] =
      loginSubscribeState.birthDate === "" && true;

    newErrorForm["phoneNumberError"] =
      !validator.isMobilePhone(loginSubscribeState.phoneNumber, "fr-FR") &&
      true;

    newErrorForm["streetError"] = loginSubscribeState.street === "" && true;

    newErrorForm["postalCodeError"] =
      !validator.isPostalCode(loginSubscribeState.postalCode, "any") && true;

    newErrorForm["cityError"] = loginSubscribeState.city === "" && true;

    newErrorForm["countryError"] = loginSubscribeState.country === "" && true;

    let validate =
      !newErrorForm.firstNameError &&
      !newErrorForm.lastNameError &&
      !newErrorForm.birthDateError &&
      !newErrorForm.streetError &&
      !newErrorForm.postalCodeError &&
      !newErrorForm.cityError &&
      !newErrorForm.countryError;

    if (validate) {
      setIsLoading(true);

      let data = {
        firstName: loginSubscribeState.firstName,
        lastName: loginSubscribeState.lastName,
        birthDate: loginSubscribeState.birthDate,
        phoneNumber: loginSubscribeState.phoneNumber,
        streetNumber: loginSubscribeState.streetNumber,
        street: loginSubscribeState.street,
        addressComplement: loginSubscribeState.addressComplement,
        postalCode: loginSubscribeState.postalCode,
        city: loginSubscribeState.city,
        country: loginSubscribeState.country,
        email: loginSubscribeState.email,
        password: loginSubscribeState.password,
        userType: loginSubscribeState.userType,
      };

      if (loginSubscribeState.userType === "host") {
        let pseudoResponse = await getAvailablePseudo(
          toUpperCamelCase(
            loginSubscribeState.firstName + " " + loginSubscribeState.lastName
          )
        );
        data["isProfessional"] = false;
        data["instantBooking"] = loginSubscribeState.instantBooking;
        data["isPseudoUpdated"] = loginSubscribeState.isPseudoUpdated;
        data["description"] = loginSubscribeState.description;
        data["pseudo"] = pseudoResponse.pseudo;
      }

      if (loginSubscribeState.updateAccountInfos) {
        const res = await UpdateUserInDatabase(loginSubscribeState.uid, data);

        if (res.status === 200) {
          loginSubscribeDispatch({ type: "CLOSE" });
        } else {
          newErrorForm["generalError"] = true;
          newErrorForm["generalErrorMessage"] = res?.errorMessage;
        }
        setIsLoading(false);
      } else {
        const res = await RegisterWithEmailAndPassword(data);

        if (res.statusCode === 200) {
          loginSubscribeDispatch({ type: "GO TO", to: "AccountWellCreated" });
        } else {
          newErrorForm["generalError"] = true;

          newErrorForm["generalErrorMessage"] = res?.errorMessage;
        }
        setIsLoading(false);
      }
    } else {
      newErrorForm["generalError"] = true;

      newErrorForm["generalErrorMessage"] =
        "Corriger ou compléter les champs en rouge";
    }

    setErrorForm(newErrorForm);
  };

  const resetValues = () => {
    setErrorForm(defaultErrorForm);
  };

  useEffect(() => {
    if (loginSubscribeState?.openModal === "none") {
      resetValues();
    }
  }, [loginSubscribeState.openModal]);
  return (
    <div className="py-12 space-y-8">
      <form className="space-y-8" onSubmit={validate}>
        <div className="center">
          <h2 className="font-bold center xs:text-[28px] leading-10 flex-wrap">
            {loginSubscribeState.updateAccountInfos
              ? "Mes informations"
              : "Mon compte"}
            <span className="pl-2 text-main/main-100">
              {!loginSubscribeState.updateAccountInfos
                ? loginSubscribeState.userType == "host"
                  ? "hôte"
                  : "voyageur"
                : ""}
            </span>
          </h2>
        </div>

        <div className="center">
          <div className="space-y-4 px-0 md:px-10">
            <div className="center">
              <TextField
                error={errorForm.lastNameError}
                value={loginSubscribeState.lastName}
                label="Nom"
                onChange={(event) => handleChange(event, "LAST NAME")}
                fullWidth
                inputProps={{
                  autoComplete: "family-name",
                }}
                InputProps={{
                  type: "text",
                }}
              />
            </div>

            <div className="center">
              <TextField
                error={errorForm.firstNameError}
                value={loginSubscribeState.firstName}
                label="Prénom"
                onChange={(event) => handleChange(event, "FIRST NAME")}
                fullWidth
                inputProps={{
                  autoComplete: "given-name",
                }}
                InputProps={{
                  type: "text",
                }}
              />
            </div>
            <div className="center">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={"fr-FR"}
              >
                <DatePicker
                  label={"Date de naissance"}
                  maxDate={maxDate}
                  minDate={minDate}
                  defaultValue={
                    loginSubscribeState.updateAccountInfos
                      ? dayjs(new Date(loginSubscribeState.birthDate))
                      : null
                  }
                  onChange={(event) => {
                    loginSubscribeDispatch({
                      type: "SET",
                      kind: "BIRTH DATE",
                      target: event ? event["$d"] : "",
                    });
                  }}
                  sx={{
                    width: "100%",
                  }}
                  slots={{
                    openPickerIcon: () => {
                      return (
                        <IonIcon
                          icon={calendarOutline}
                          className="w-6 h-6 text-black p-[1px]"
                        ></IonIcon>
                      );
                    },
                  }}
                  slotProps={{
                    textField: {
                      error: errorForm.birthDateError,
                    },
                  }}
                  format="DD-MM-YYYY"
                  inputProps={{
                    autoComplete: "bday",
                  }}
                />
              </LocalizationProvider>
            </div>

            <div className="center">
              <TextField
                error={errorForm.phoneNumberError}
                value={loginSubscribeState.phoneNumber}
                label="Téléphone"
                fullWidth
                onChange={(event) => handleChange(event, "PHONE NUMBER")}
                InputProps={{
                  type: "tel",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IonIcon
                        icon={callOutline}
                        className="w-6 h-6 text-main/dark-100 p-[1px]"
                      ></IonIcon>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  autoComplete: "tel",
                }}
              />
            </div>

            <div className="center grid grid-cols-2 space-x-2 ">
              <TextField
                error={errorForm.streetNumberError}
                value={loginSubscribeState.streetNumber}
                label="N°"
                name="street-number"
                sx={{
                  width: "100px",
                }}
                onChange={(event) => handleChange(event, "STREET NUMBER")}
                InputProps={{
                  type: "text",
                }}
                inputProps={{
                  autoComplete: "address-line1",
                }}
              />

              <TextField
                error={errorForm.streetError}
                value={loginSubscribeState.street}
                label="Rue"
                onChange={(event) => handleChange(event, "STREET")}
                fullWidth
                InputProps={{
                  type: "text",
                }}
                inputProps={{
                  autoComplete: "address-line1",
                }}
              />
            </div>

            <div className="center">
              <TextField
                value={loginSubscribeState.addressComplement}
                label="Complément d'adresse (facultatif)"
                onChange={(event) => handleChange(event, "ADDRESS COMPLEMENT")}
                fullWidth
                InputProps={{
                  type: "text",
                }}
                inputProps={{
                  autoComplete: "address-line2",
                }}
              />
            </div>

            <div className="center grid grid-cols-2 space-x-2">
              <TextField
                error={errorForm.postalCodeError}
                value={loginSubscribeState.postalCode}
                label="Code postal"
                onChange={(event) => handleChange(event, "POSTAL CODE")}
                sx={{
                  width: "40%",
                }}
                InputProps={{
                  type: "number",
                }}
                inputProps={{
                  autoComplete: "postal-code",
                }}
              />

              <TextField
                error={errorForm.cityError}
                value={loginSubscribeState.city}
                label="Ville"
                sx={{
                  width: "60%",
                }}
                onChange={(event) => handleChange(event, "CITY")}
                fullWidth
                InputProps={{
                  type: "text",
                }}
                inputProps={{
                  autoComplete: "address-level1",
                }}
              />
            </div>

            <SelectCountry
              error={errorForm.countryError}
              handleChange={(event) => {
                handleChange(event, "COUNTRY");
              }}
              state={loginSubscribeState}
            />
          </div>
        </div>

        {isLoading ? (
          <div className="flex w-full justify-center">
            <CircularProgress size={40} thickness={4} />
          </div>
        ) : (
          <div className="flex flex-col items-center space-y-3">
            <button
              type="submit"
              disabled={buttonDisabled}
              className="purple-button"
            >
              {loginSubscribeState.updateAccountInfos
                ? "Enregistrer"
                : "CR" + "\u00c9" + "ER UN COMPTE"}
            </button>

            {errorForm?.generalError && (
              <p className="block text-xs font-inter text-semantic/error">
                {errorForm?.generalErrorMessage}
              </p>
            )}
          </div>
        )}
      </form>
    </div>
  );
};

export const PrivateHostAccountDetails = () => {
  const { loginSubscribeState } = useAuth();

  return (
    <AuthModal
      children={AccountDetailsContent()}
      isOpen={loginSubscribeState.openModal == "PrivateHostAccountDetails"}
      width="480px"
      height="900px"
      withHeader={!loginSubscribeState?.updateAccountInfos}
    />
  );
};

export const TravelerAccountDetails = () => {
  const { loginSubscribeState } = useAuth();

  return (
    <AuthModal
      children={AccountDetailsContent()}
      isOpen={loginSubscribeState.openModal == "TravelerAccountDetails"}
      width="480px"
      height="900px"
      withHeader={!loginSubscribeState?.updateAccountInfos}
    />
  );
};
