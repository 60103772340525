import React, { useState } from "react";
import AdModal from "./AdModal";
import useAd from "../../hooks/useAd";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import useAuth from "../../hooks/useAuth";
import CarouselSlider from "../CarouselSlider";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ServiceAndCategoryCard from "../../components/ServiceAndCategoryCard";
import { slotrFeePercentage } from "../../App";

function CheckboxUserConditions({
  userConditionsError,
  userConditionsChecked,
  handleClick,
}) {
  return (
    <FormControl error={userConditionsError}>
      <FormControlLabel
        control={
          <Checkbox
            color="default"
            checked={userConditionsChecked}
            onChange={handleClick}
            sx={{
              transform: "scale(0.7)",
              color: "black",
              position: "absolute",
              top: "-9px",
              left: "-25px",
            }}
          />
        }
        label={
          <FormLabel
            sx={{
              "&.Mui-focused": {
                color: "var(--second-color)",
              },

              color: "var(--second-color)",
              fontSize: "14px",
              verticalAlign: "middle",
            }}
          >
            <div className="leading-[25px] block">
              J'accepte les{" "}
              <Link
                className="underline"
                to="/documents/CGVS_Hôtes.pdf"
                target="_blank"
                rel="noreferrer"
              >
                CGVS
              </Link>{" "}
              de la plateforme
            </div>
          </FormLabel>
        }
        sx={{
          position: "relative",
          top: 0,
          left: "30px",
          paddingRight: "10px",
          paddingLeft: "22px",
        }}
      />
    </FormControl>
  );
}

const VerifyContent = () => {
  const { handleNext, startAd, setStartAd, setActiveStep, addProperty } =
    useAd();
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuth();
  const { category } = startAd;

  const [userConditionsError, setUserConditionsError] = useState(false);
  const handleClickConditions = () => {
    setStartAd({
      ...startAd,
      userConditionsChecked: !startAd.userConditionsChecked,
    });
  };
  const validate = async () => {
    if (startAd?.userConditionsChecked) {
      setIsLoading(true);
      const property = await addProperty(currentUser?.uid, startAd);

      if (property?.id) {
        window.localStorage.removeItem(
          `CREATE_AN_AD_STATE_${currentUser?.uid}`
        );
        handleNext();
      } else {
        setActiveStep(8);
      }

      setIsLoading(false);
    } else {
      setUserConditionsError(true);
    }
  };

  const totalPrice = (price) => {
    const slotrFee = price * (slotrFeePercentage / 100);
    const vatSlotrFee = parseFloat(slotrFee) * 0.2;
    const totalSlotrFee = parseFloat(slotrFee) + parseFloat(vatSlotrFee);
    const totalPrice = parseFloat(price) + parseFloat(totalSlotrFee);

    return totalPrice.toFixed(2);
  };
  const renderTextWithLineBreaks = (text) => {
    const lines = text.split("\n");
    return (
      <>
        {lines.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index < lines.length - 1 && <br />}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="">
        <h4 className="text-center pb-4">Vérifiez votre annonce</h4>

        <div className="w-full h-[240px]">
          <CarouselSlider
            pictures={startAd?.picturesUrls}
            moreStyle="rounded-lg"
          />
        </div>

        <div className="flex justify-between items-center space-x-4 pt-5 pb-8 ">
          <h2 className="flex font-semibold text-[20px] text-neutral-950 text-xl  leading-[30px]">
            {startAd?.title}
          </h2>

          <p className=" ">
            <span className="font-semibold ">
              {totalPrice(startAd?.price)}
              &#8364;/
            </span>
            nuit
          </p>
        </div>

        <p className="leading-relaxed">
          {renderTextWithLineBreaks(startAd?.description)}
          <br />
          <br />
          {startAd?.streetNumber + " " + startAd?.street} <br />
          {startAd?.postalCode + " " + startAd?.city.toUpperCase()} <br />
        </p>

        <div className="pt-8 h-9 text-black text-xl font-semibold  leading-loose">
          Type de logement
        </div>

        <div className="grid grid-cols-3 space-x-8 pt-14">
          <div className="center">
            <ServiceAndCategoryCard name={category} type={"category"} />
          </div>

          <div className="col-span-2">
            <p className="w-48 text-black  leading-relaxed">
              {startAd?.entireAccomodation
                ? "Logement entier"
                : "Une partie du logement"}
              <br />
              <br />
              {parseInt(startAd?.numberOfBeds) +
                (parseInt(startAd?.numberOfBeds) > 1 ? " lits" : " lit")}
              <br />
              {parseInt(startAd?.numberOfTravelers) +
                (parseInt(startAd?.numberOfTravelers) > 1
                  ? " personnes "
                  : " personne ")}
              maximum
            </p>
          </div>
        </div>
        <div className="pt-8 ">
          <CheckboxUserConditions
            userConditionsError={userConditionsError}
            userConditionsChecked={startAd?.userConditionsChecked}
            handleClick={handleClickConditions}
          />
        </div>

        {isLoading ? (
          <div className="flex w-full justify-center pt-10">
            <CircularProgress size={40} thickness={4} />
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-6 gap-y-20 pt-10">
            <div className="relative">
              <div className="absolute right-0">
                <button
                  className="white-button center transition-none !w-[111px] "
                  onClick={() => setActiveStep(0)}
                >
                  MODIFIER
                </button>
              </div>
            </div>

            <button
              className="purple-button center relative left-0 !w-[111px]"
              onClick={validate}
            >
              VALIDER
            </button>
          </div>
        )}
      </div>
    </>
  );
};
const Verify = ({}) => {
  return (
    <AdModal
      children={<VerifyContent />}
      stepNumber={6}
      withMdScrolling={true}
      height="900px"
    />
  );
};
export default Verify;
