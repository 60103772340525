import { useEffect, useState } from "react";
import useAd from "../../hooks/useAd";
import { useParams } from "react-router-dom";

const AllowedDeparturesAndArrivals = () => {
  const { editAd, updatePropertyFromDatabase } = useAd();
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [show, setShow] = useState(false);
  const { propertyUid } = useParams();
  const daysOfWeek = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];

  const handleSaveModifications = async () => {
    setShow(false);
    setShowSaveButton(false);
    await updatePropertyFromDatabase(propertyUid, {
      forbiddenArrivalDays: editAd?.forbiddenArrivalDays || [],
      forbiddenDepartureDays: editAd?.forbiddenDepartureDays || [],
    });
  };

  return (
    <div className="w-full pt-[24px] space-y-[4px]">
      <p className="text-base font-semibold">Arrivées et départs autorisés</p>
      <p className="text-neutral/gray/gray-100 text-xs ">
        Les voyageurs ne pourront pas arriver ou partir sur les jours
        sélectionnés.
      </p>

      {show ? (
        <div className="p-[24px] border-[1px] border-neutral/gray/gray-40 space-y-[32px]">
          <div>
            <p className="text-sm font-semibold leading-snug ">
              Arrivée non autorisée
            </p>
            <p className="text-neutral/gray/gray-100 text-xs pt-[4px]">
              Définissez des jours ou vous n’autorisez pas l’arrivée des
              voyageurs.
            </p>
            <div className="-mx-[8px] pt-4">
              {daysOfWeek.map((day) => {
                return (
                  <DayButton
                    key={day}
                    day={day}
                    indexOfDay={daysOfWeek.indexOf(day)}
                    attribute={"forbiddenArrivalDays"}
                    setShowSaveButton={setShowSaveButton}
                  />
                );
              })}
            </div>
          </div>

          <div>
            <p className="text-sm font-semibold leading-snug">
              Départ non autorisé
            </p>
            <p className="text-neutral/gray/gray-100 text-xs">
              Définissez des jours ou vous n’autorisez pas le départ des
              voyageurs.
            </p>
            <div className="-mx-[8px] pt-4">
              {daysOfWeek.map((day) => {
                return (
                  <DayButton
                    key={day}
                    day={day}
                    indexOfDay={daysOfWeek.indexOf(day)}
                    attribute={"forbiddenDepartureDays"}
                    setShowSaveButton={setShowSaveButton}
                  />
                );
              })}
            </div>
          </div>
          {showSaveButton && (
            <button
              className="purple-button !w-32"
              onClick={handleSaveModifications}
            >
              ENREGISTRER
            </button>
          )}
        </div>
      ) : (
        <p
          className=" border-[1px] border-neutral/gray/gray-40 text-[14px] px-[16px] py-[8px]"
          onClick={() => {
            setShow(true);
          }}
        >
          Définir les jours d’arrivée et de départ non autorisés
        </p>
      )}
    </div>
  );
};

const DayButton = ({ day, indexOfDay, attribute, setShowSaveButton }) => {
  const { editAd, setEditAd } = useAd();
  const [activated, setActivated] = useState(false);
  useEffect(() => {
    if (
      editAd.hasOwnProperty(attribute) &&
      editAd[attribute].includes(indexOfDay)
    ) {
      setActivated(true);
    } else {
      setActivated(false);
    }
  }, [editAd]);

  const handleClick = () => {
    setShowSaveButton(true);
    let newAttribute;
    if (
      editAd.hasOwnProperty(attribute) &&
      editAd[attribute].includes(indexOfDay)
    ) {
      newAttribute = [
        ...editAd[attribute].filter((item) => item !== indexOfDay),
      ];
    } else if (editAd.hasOwnProperty(attribute)) {
      newAttribute = [...editAd[attribute], indexOfDay];
    } else {
      newAttribute = [indexOfDay];
    }

    setEditAd({ ...editAd, [attribute]: newAttribute });
  };
  return (
    <button
      className={`border-[1px] ${
        activated
          ? "border-main/main-100  bg-main/main-100 bg-opacity-5 "
          : "border-neutral/gray/gray-60"
      } rounded-[6px] text-xs p-[8px] mx-[8px] my-[4px]`}
      onClick={handleClick}
    >
      {day}
    </button>
  );
};

export default AllowedDeparturesAndArrivals;
