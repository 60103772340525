import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import useWindowsSize from "../hooks/useWindowsSize";
import HostNavBar from "../components/HostNavBar";
import CircularProgress from "@mui/material/CircularProgress";
import HeroTitle from "../components/HeroTitle";
import { useNavigate } from "react-router-dom";
import { IonIcon } from "@ionic/react";
import { ellipse } from "ionicons/icons";
import { API } from "aws-amplify";
import { BUILD_ENV } from "../App";
import { hostname } from "../App";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TextField } from "@mui/material";
import GenericModal from "../components/Modal";
import { Link } from "react-router-dom";
import ProfilePicture from "../components/ProfilePicture";

const updatePropertiesOfAHost = async (userUid, data) => {
  try {
    const apiName = "propertiesManager";
    const path = `/propertiesOfAHost/${userUid}`;
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    let response;

    if (BUILD_ENV === "localhost") {
      options["method"] = "PUT";
      options["body"] = JSON.stringify(data);
      response = await fetch(`http://${hostname}:3001${path}`, options);
      response = await response.json();
    } else {
      options["body"] = data;
      response = await API.put(apiName, path, options);
    }

    return response;
  } catch (error) {
    console.log(error);
  }
};

const ToggleProfile = ({ firstValue, secondValue, disabled = false }) => {
  const [selectValue, setSelectValue] = useState("Oui");
  const { UpdateUserInDatabase, loginSubscribeState } = useAuth();

  const handleInstantBooking = async () => {
    await UpdateUserInDatabase(loginSubscribeState.uid, {
      instantBooking: !loginSubscribeState?.instantBooking,
    });
    await updatePropertiesOfAHost(loginSubscribeState.uid, {
      instantBooking: !loginSubscribeState?.instantBooking,
    });
  };
  function handleSetSelectValue() {
    setSelectValue(
      !loginSubscribeState.hasOwnProperty("instantBooking") ||
        loginSubscribeState?.instantBooking
        ? "Oui"
        : "Non"
    );
  }
  useEffect(() => {
    handleSetSelectValue();
  }, [loginSubscribeState]);
  return (
    <div className="flex space-x-2">
      <p
        className={`${
          selectValue === secondValue && "text-neutral/gray/gray-100"
        }`}
      >
        {firstValue}
      </p>

      <div
        className={`flex rounded-full ${
          disabled ? "bg-neutral/gray/gray-60" : "bg-main/main-100"
        } w-12 h-6 items-center px-1 cursor-pointer ${
          selectValue === firstValue ? "justify-start" : "justify-end"
        }`}
        onClick={handleInstantBooking}
      >
        <div className="bg-white w-4 h-4 rounded-full"></div>
      </div>

      <p
        className={`${
          selectValue === firstValue && "text-neutral/gray/gray-100"
        }`}
      >
        {secondValue}
      </p>
    </div>
  );
};

const Verification = ({ field }) => {
  const { title, description, status, actionName, action } = field;
  return (
    <div className="flex items-start xl:items-center space-x-4">
      <div className="flex-none">
        {status === "verified" ? (
          <img
            className="w-10 h-10"
            src="/images/navigation-signs/green-checked-mark.svg"
            alt="green checked mark"
          />
        ) : status === "pending" ? (
          <img
            className="w-10 h-10"
            src="/images/navigation-signs/timer-icon.svg"
            alt="timer"
          />
        ) : (
          <img
            className="w-10 h-10"
            src="/images/navigation-signs/exclamation-mark.svg"
            alt="exclamation mark"
          />
        )}
      </div>

      <div className="flex flex-col space-y-3 xl:space-y-0 w-full xl:justify-between xl:items-center xl:flex-row xl:space-x-4">
        <div>
          <p>{title}</p>

          {status === "unverified" && (
            <p className="text-xs text-neutral/gray/gray-100">{description}</p>
          )}

          {status === "pending" && (
            <p className="text-xs text-neutral/gray/gray-100">
              En cours de verification
            </p>
          )}
        </div>

        {status === "unverified" && (
          <button
            className="purple-button !h-[32px] !w-[109px] flex-none"
            onClick={action}
          >
            {actionName}
          </button>
        )}
      </div>
    </div>
  );
};

const ProfileHeaderTraveler = () => {
  return (
    <HeroTitle
      title={"Mon profil"}
      maxWidth={"max-w-smallScreen"}
      hidden={"hidden lg:flex"}
    />
  );
};

const Profile = () => {
  const {
    currentUser,
    Logout,
    loginSubscribeDispatch,
    loginSubscribeState,
    UpdateUserInDatabase,
    UpdateUserPseudo,
  } = useAuth();
  const [description, setDescription] = useState("");
  const [pseudoModalIsOpen, setPseudoModalIsOpen] = useState(false);
  const windowWidth = useWindowsSize();
  const [pseudo, setPseudo] = useState("");
  const [isPseudoUpdated, setIsPseudoUpdated] = useState(false);

  useEffect(() => {
    if (currentUser) {
      let data = { ...currentUser };
      delete data.creationTime;
      delete data.updateTime;
      data.updateAccountInfos = true;
      data.rcsSirenSiret = currentUser.rcsSirenSiret;
      setPseudo(currentUser?.pseudo);
      setIsPseudoUpdated(
        currentUser?.isPseudoUpdated && isPseudoUpdated != undefined
      );
      setDescription(currentUser?.description);

      loginSubscribeDispatch({
        type: "SET ALL",
        target: data,
      });
    }
  }, [currentUser]);

  const openUpdateInfosModal = () => {
    if (currentUser !== null) {
      const next =
        currentUser.userType === "traveler"
          ? "TravelerAccountDetails"
          : currentUser.userType === "host" && currentUser.isProfessional
          ? "ProfessionalHostAccountDetails"
          : "PrivateHostAccountDetails";

      loginSubscribeDispatch({
        type: "GO TO",
        to: next,
      });
    }
  };
  const navigate = useNavigate();

  const standarRequirements = [
    {
      title: "E-mail",
      description:
        "Afin de valider votre compte, la validation de l'e-mail est nécessaire",
      status: currentUser?.emailVerified ? "verified" : "unverified",
      actionName: "Envoyer",
      action: () =>
        loginSubscribeDispatch({
          type: "GO TO",
          to: "AccountWellCreated",
        }),
    },
  ];

  const hostRequirements = [
    ...standarRequirements,
    {
      title: "Identité",
      description:
        "Afin de valider votre identité, une CNI ou un passeport est nécessaire",
      status:
        currentUser?.dataOfMerchant?.compliance.requirements.identityStatus,
      actionName: "Envoyer",
      action: () =>
        (window.location.href =
          currentUser?.dataOfMerchant?.compliance.requirements.identityVerificationUrl),
    },
    {
      title: "Coordonnées bancaires",
      description:
        "Afin de publier vos annonces, nous aurions besoin de tes coordonnées bancaires",
      status:
        currentUser?.dataOfMerchant?.compliance.requirements.bankAccountStatus,
      actionName: "Envoyer",
      action: () =>
        (window.location.href =
          currentUser?.dataOfMerchant?.compliance.requirements.bankAccountVerificationUrl),
    },
  ];

  const hostProRequirements = [
    ...hostRequirements,
    {
      title: "Extrait de la chambre de commerce (KBIS)",
      description:
        "Afin de publier vos annonces, un extrait de la chambre des commerces est nécessaire",
      status:
        currentUser?.dataOfMerchant?.compliance.requirements.cocExtractStatus,
      actionName: "Envoyer",
      action: () =>
        (window.location.href =
          currentUser?.dataOfMerchant?.compliance.requirements.cocExtractVerificationUrl),
    },
    {
      title: "Registre UBO",
      description:
        "Afin de publier vos annonces, un registre UBO est nécessaire",
      status: currentUser?.dataOfMerchant?.compliance.requirements.uboStatus,
      actionName: "Envoyer",
      action: () =>
        (window.location.href =
          currentUser?.dataOfMerchant?.compliance.requirements.uboVerificationUrl),
    },
  ];

  const travelerRequirements = [
    ...standarRequirements,
    {
      title: "Identité",
      description:
        "Afin de valider votre identité, une CNI ou un passeport est nécessaire",
      status: false,
      actionName: "Envoyer",
      action: () => navigate(""),
    },
  ];

  const othersRequirements = {
    title: "Autres documents à renseigner",
    description:
      "Documents pouvant être demandés par notre prestataire de paiement pour des vérifications approfondies",
    status: currentUser?.dataOfMerchant ? "unverified" : false,
    actionName: "Voir",
    action: () =>
      (window.location.href =
        currentUser?.dataOfMerchant?.compliance.overviewUrl),
  };

  if (currentUser) {
    const {
      firstName,
      lastName,
      phoneNumber,
      userType,
      email,
      personInChargeName,
      isProfessional,
      companyName,
      rcsSirenSiret,
      street,
      streetNumber,
      postalCode,
      addressComplement,
      city,
      country,
      birthDate,
    } = currentUser;
    const isTraveler = userType === "traveler";
    const isPro = isProfessional;
    const profilStatus = currentUser?.dataOfMerchant?.status;
    const profileComplianceStatus =
      currentUser?.dataOfMerchant?.compliance.status;

    let requirements = [];

    if (currentUser?.emailVerified) {
      if (isTraveler) {
        requirements = travelerRequirements;
      } else {
        if (isPro) {
          requirements = hostProRequirements;
        } else {
          requirements = hostRequirements;
        }
        requirements.push(othersRequirements);
      }
    } else {
      requirements = standarRequirements;
    }

    function userBirthDate() {
      const date_new = new Date(birthDate);
      const datePart = new Intl.DateTimeFormat("fr-FR").format(date_new);

      return datePart;
    }

    function userCreationDate() {
      const dateObj = new Date(currentUser?.creationTime._seconds * 1000);
      const datePart = dateObj.toLocaleDateString();

      return datePart;
    }

    const PseudoModal = () => {
      const [inputValue, setInputValue] = useState(pseudo);
      const [errorPseudo, setErrorPseudo] = useState(false);
      const [pseudoProposal, setPseudoProposal] = useState("");
      const handlePseudoSubmit = async (pseudoToSubmit) => {
        setPseudo(pseudoToSubmit);
        const response = await UpdateUserPseudo(
          loginSubscribeState.uid,
          pseudoToSubmit
        );
        console.log(response);
        if (response != "ok") {
          console.log();
          setErrorPseudo(true);
          setPseudoProposal(response.newPseudo);
        } else {
          setPseudoModalIsOpen(false);
          setIsPseudoUpdated(true);
        }
      };
      return (
        <GenericModal
          children={
            <div
              className={`pt-[110px] pb-[60px] flex-col  center space-y-10  ${
                !errorPseudo && "pt-[150px]"
              }`}
            >
              <h4 className="font-bold text-[24px]  pb-6 center ">
                Modifiez votre pseudo
              </h4>
              <div>
                <TextField
                  value={inputValue}
                  error={errorPseudo}
                  onChange={(event) => setInputValue(event.target.value)}
                  InputProps={{
                    type: "text",
                    sx: { fontSize: "14px", width: "330px" },
                  }}
                />
                {errorPseudo && (
                  <p className="flex justify-end text-[12px] text-semantic/error">
                    Ce pseudo n'est pas disponible
                  </p>
                )}
              </div>
              {errorPseudo && (
                <div className="text-[14px] w-[330px]">
                  <p className="font-semibold	">Pseudo disponible :</p>
                  <div className="flex items-center justify-between">
                    <p>{pseudoProposal}</p>
                    <button
                      className="purple-button center transition-none !text-[12px] !h-[35px] !w-[105px] !font-medium "
                      onClick={() => {
                        handlePseudoSubmit(pseudoProposal);
                      }}
                    >
                      Choisir
                    </button>
                  </div>
                </div>
              )}

              <button
                className="purple-button center transition-none !text-[12px] !h-[40px] !w-[125px] !font-medium "
                onClick={() => {
                  handlePseudoSubmit(inputValue);
                }}
                disabled={errorPseudo}
              >
                Enregistrer
              </button>
            </div>
          }
          isOpen={pseudoModalIsOpen}
          handleClose={() => {
            setPseudoModalIsOpen(false);
          }}
          withHeader={false}
          height="600px"
        />
      );
    };

    const Description = () => {
      const [descriptionInput, setDescriptionInput] = useState(description);
      const [isModify, setIsModify] = useState(false);
      const handleSubmitDescription = async () => {
        setIsModify(false);
        setDescription(descriptionInput);
        await UpdateUserInDatabase(loginSubscribeState.uid, {
          description: descriptionInput,
        });
      };
      return (
        <>
          {isModify && (
            <>
              <TextField
                value={descriptionInput}
                id="outlined-multiline-static"
                multiline
                fullWidth
                rows={5}
                label="Description"
                helperText={`${descriptionInput?.length}/5000 caractères`}
                onChange={(event) => setDescriptionInput(event.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "var(--first-color)",
                    },
                  },
                  "& .MuiFormLabel-root": {
                    "&.Mui-focused": {
                      color: "var(--first-color)",
                    },
                  },
                }}
                inputProps={{
                  maxLength: 5000,
                  sx: {
                    paddingRight: 8,
                  },
                }}
              />
              <button
                className="purple-button center transition-none !text-[12px] !h-[40px] !w-[105px]   !font-medium "
                onClick={handleSubmitDescription}
              >
                VALIDER
              </button>
            </>
          )}
          {!isModify && description && (
            <div className="flex flex-col gap-1">
              <p className="text-sm">{description}</p>
              <button
                className="flex space-x-2 items-center cursor-pointer"
                onClick={() => {
                  setIsModify(true);
                }}
              >
                <img
                  src="/images/pencil-icon.svg"
                  alt="pencil-icon"
                  className="w-6 h-6"
                />
                <span className="text-design-system/highlight text-sm ">
                  Modifier
                </span>
              </button>
            </div>
          )}
          {!isModify && !description && (
            <div className="space-y-2">
              <p className="text-sm">
                Vous pouvez ajouter une description qui sera visible auprès des
                voyageurs. Cette description apparaîtra dans votre page de
                présentation qui regroupera l’ensemble de vos annonces.
              </p>
              <button
                className="text-sm"
                onClick={() => {
                  setIsModify(true);
                }}
              >
                + Ajouter une description
              </button>
            </div>
          )}
        </>
      );
    };

    return (
      <main className="flex-grow" id="profile">
        <PseudoModal />
        {isTraveler ? (
          <ProfileHeaderTraveler />
        ) : (
          <HostNavBar focused="profile" />
        )}

        <div className="text-black lg:p-0 lg:flex lg:justify-center lg:items-center ">
          <div
            className={`lg:w-[1440px] px-6 md:px-10 lg:px-16 ${
              isTraveler && "lg:pt-[109px]"
            }`}
          >
            {!isTraveler && (
              <h1 className="text-[28px] lg:text-4xl font-bold pb-5 lg:pb-10 lg:pt-[61px] ">
                Mon profil
              </h1>
            )}

            <div className="flex flex-col xl:flex xl:flex-row xl:gap-14">
              <div className="flex-none space-y-6 lg:space-y-10">
                <div className="w-full lg:w-full bg-neutral/gray/gray-10 rounded-2xl relative">
                  <div className="px-6 md:px-10 xl:px-14 py-4 md:py-6 lg:py-8">
                    <div className="flex flex-row gap-6 lg:gap-8 ">
                      <ProfilePicture />

                      <div className="center z-10">
                        <div className="flex flex-col xs:text-sm space-y-2 lg:space-y-4">
                          <h3 className="lg:leading-8 capitalize">
                            {isTraveler || (!isTraveler && !isPro)
                              ? firstName + " " + lastName
                              : companyName}
                          </h3>
                          {!isTraveler &&
                            (isPseudoUpdated ? (
                              <div className="flex flex-row gap-1">
                                <p>{pseudo}</p>
                                <button
                                  onClick={() => {
                                    setPseudoModalIsOpen(true);
                                  }}
                                >
                                  <img
                                    src="/images/pencil-icon.svg"
                                    alt="pencil-icon"
                                    className="w-6 h-6 center"
                                  />
                                </button>
                              </div>
                            ) : (
                              <button
                                className="text-main/main-100 text-xs font-semibold rounded-full border-2 border-main/main-100 w-[183px] h-[32px] center"
                                onClick={() => {
                                  setPseudoModalIsOpen(true);
                                }}
                              >
                                <div className="flex flex-row gap-2">
                                  <div className="center">
                                    <img
                                      className="w-[16px] h-[16px] !text-main/main-100 center"
                                      src="/images/navigation-signs/purple-add-icon.svg"
                                      alt="add icon"
                                    />
                                  </div>
                                  <p className="center">AJOUTER UN PSEUDO</p>
                                </div>
                              </button>
                            ))}

                          <div className="space-y-2">
                            <div>{"Inscription le " + userCreationDate()}</div>
                            <div className="flex flex-row gap-2 lg:gap-4 items-center ">
                              {isTraveler ? (
                                <span>Voyageur</span>
                              ) : (
                                <span> Hôte</span>
                              )}

                              {isPro && (
                                <span className="text-main/main-100 text-xs font-semibold rounded-full border-2 border-main/main-100 w-[63px] h-[32px] center">
                                  PRO
                                </span>
                              )}
                            </div>

                            <div>{"Inscription le " + userCreationDate()}</div>

                            {currentUser?.emailVerified && !isTraveler && (
                              <div>
                                <div className="flex space-x-2.5 items-center">
                                  <IonIcon
                                    icon={ellipse}
                                    className={`shrink-0 w-2 h-2 ${
                                      profilStatus === "live"
                                        ? "text-semantic/succes"
                                        : profilStatus === "pending"
                                        ? "text-semantic/break"
                                        : "text-semantic/error"
                                    }`}
                                  ></IonIcon>

                                  <p className="text-sm">
                                    {"Statut du Profil : " +
                                      (profilStatus === "Nouveau"
                                        ? ""
                                        : profilStatus === "live"
                                        ? "Vérifié "
                                        : profilStatus === "pending"
                                        ? "En attente"
                                        : "suspendu")}
                                  </p>
                                </div>

                                <div className="flex space-x-2.5 items-center">
                                  <IonIcon
                                    icon={ellipse}
                                    className={`shrink-0 w-2 h-2 ${
                                      profileComplianceStatus === "verified"
                                        ? "text-semantic/succes"
                                        : profileComplianceStatus === "pending"
                                        ? "text-semantic/break"
                                        : "text-semantic/error"
                                    }`}
                                  ></IonIcon>

                                  <p className="text-sm">
                                    {"Statut de conformité : " +
                                      (profileComplianceStatus === "verified"
                                        ? "Vérifié "
                                        : profileComplianceStatus === "pending"
                                        ? "En attente"
                                        : "Non vérifié")}
                                  </p>
                                </div>
                              </div>
                            )}
                            <button
                              className="flex lg:hidden text-sm text-main/dark-70"
                              onClick={Logout}
                            >
                              Se déconnecter
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="absolute -top-3 right-0 lg:-top-2 md:right-4">
                    <img
                      className="w-16 h-16 lg:w-[124px] lg:h-[124px] "
                      src="/images/stickers/sticker-cloud.svg"
                      alt="sticker cloud"
                    />
                  </div>
                  <div className="absolute top-6 -left-3 ">
                    <img
                      className="w-10 h-10 lg:w-[104px] lg:h-[92px]"
                      src="/images/stickers/sticker-green.svg"
                      alt="sticker green"
                    />
                  </div>
                  <div className="absolute bottom-4 lg:bottom-8 right-4 lg:right-12 z-0">
                    <img
                      className="w-16 h-16 lg:w-[104px] lg:h-[104px] "
                      src="/images/stickers/sticker-strike.svg"
                      alt="sticker strike"
                    />
                  </div>
                </div>

                <div className="px-6 md:px-10 xl:px-14 lg:w-full py-4 md:py-6 lg:py-8 rounded-2xl border border-[#D9D9D9]">
                  <div className="flex flex-col gap-2 lg:gap-5 ">
                    <h4 className=" text-[16px] lg:text-2xl font-bold ">
                      Vérifications de mon compte
                    </h4>

                    <div className="space-y-4">
                      {requirements.map((field) => {
                        return <Verification field={field} key={field.title} />;
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <br className="w-6 xl:hidden" />
              <div className="h-full space-y-[32px]">
                <div
                  className={`px-6 md:px-10 xl:px-14 h-full py-4 md:py-6 lg:py-8 rounded-2xl border border-#98949E`}
                >
                  <h4 className="text-[16px] lg:text-2xl font-bold pb-4">
                    Informations personnelles et professionnelles
                  </h4>

                  <div className="lg:flex lg:space-x-28 xl:space-x-14 2xl:space-x-28 space-y-4 lg:space-y-0">
                    <div className="flex-col flex">
                      <p className="capitalize">
                        {isTraveler || (!isTraveler && !isPro)
                          ? firstName + " " + lastName
                          : companyName}
                      </p>

                      {isPro && <p>{personInChargeName}</p>}

                      <p>{userBirthDate()}</p>

                      <p>{email}</p>

                      {isPro && <p>{rcsSirenSiret}</p>}
                    </div>

                    <hr className="my-[24px] lg:hidden"></hr>

                    <div className="flex-col flex">
                      <p>{phoneNumber}</p>

                      <p className="capitalize">
                        {streetNumber} {street}
                      </p>

                      <p>{addressComplement}</p>

                      <p className="capitalize">
                        {postalCode} {city}
                      </p>

                      <p className="capitalize">{country}</p>

                      <button
                        className="flex space-x-2 items-center cursor-pointer"
                        onClick={openUpdateInfosModal}
                      >
                        <img
                          src="/images/pencil-icon.svg"
                          alt="pencil-icon"
                          className="w-6 h-6"
                        />

                        <span className="text-design-system/highlight text-sm ">
                          Modifier
                        </span>
                      </button>
                    </div>
                  </div>

                  <div className="border-t-neutral/gray/gray-20 border-t space-y-4 mt-6 pt-6">
                    {!isTraveler && (
                      <div>
                        <div className="space-y-3">
                          <h4 className="text-[18px] lg:text-2xl font-bold ">
                            Paramètres
                          </h4>
                          {windowWidth > 1024 ? (
                            <div className="lg:flex lg:justify-between ">
                              <h6 className="text-[16px] lg:text-[18px]">
                                Réservation instantanée
                              </h6>
                              <ToggleProfile
                                firstValue={"Non"}
                                secondValue={"Oui"}
                              />
                            </div>
                          ) : (
                            <div className="space-y-4">
                              <ToggleProfile
                                firstValue={"Non"}
                                secondValue={"Oui"}
                              />
                              <h6 className="text-[16px] lg:text-[18px]">
                                Réservation instantanée
                              </h6>
                            </div>
                          )}

                          <p className="text-sm lg:pr-[110px]">
                            La réservation instantanée vous permet d'accepter
                            <strong> automatiquement </strong> les demandes de
                            réservation sans validation manuelle. En activant
                            cette option, toutes vos annonces seront configurées
                            pour la réservation instantanée.
                          </p>
                        </div>
                        <div className="border-t-neutral/gray/gray-20 border-t space-y-4 mt-6 pt-6" />
                        <div className="space-y-4">
                          <h4 className="text-[18px] lg:text-2xl font-bold ">
                            Données réglementaires
                          </h4>

                          <p className="text-sm">
                            Dès qu’une première réservation sera effectuée sur
                            vos biens, nous vous contacterons pour procéder à
                            une vérification de votre identité ainsi que de vos
                            coordonnées bancaires. Soyez assuré(e) que cette
                            étape est mise en place dans un souci de sécurité et
                            de protection des utilisateurs de la plateforme.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="px-6 md:px-10 xl:px-14 h-full py-4 md:py-6 lg:py-8 rounded-2xl border border-#98949E space-y-4">
                  <h4 className="text-[18px] lg:text-2xl font-bold ">
                    Informations sur mon profil
                  </h4>
                  <Description />

                  {!isTraveler && (
                    <div className="border-t-neutral/gray/gray-20 border-t space-y-4 mt-6 pt-6">
                      <h4 className="text-[18px] lg:text-2xl font-bold ">
                        Liens de partage
                      </h4>
                      {pseudo == undefined ? (
                        <p className=" text-sm py-3">
                          Ajoutez un pseudo pour créer votre page de
                          présentation.
                        </p>
                      ) : (
                        <>
                          <p className="text-sm">
                            Vous pouvez changer le nom du lien en modifiant
                            votre pseudo.
                          </p>
                          <div className="flex flex-row gap-6">
                            <TextField
                              value={`slotr.io/host/${pseudo}`}
                              InputProps={{
                                type: "text",
                              }}
                              inputProps={{
                                sx: {
                                  fontSize: "14px",
                                  height: "22px",
                                  width: "300px",
                                },
                              }}
                            />
                            <CopyToClipboard text={`slotr.io/host/${pseudo}`}>
                              <button>
                                <img src="/images/navigation-signs/copy.svg" />
                              </button>
                            </CopyToClipboard>
                          </div>
                          <div className="pt-2">
                            <Link
                              to={pseudo != undefined ? `/host/${pseudo}` : "#"}
                            >
                              <button
                                className="purple-button center transition-none !text-[12px] !h-[40px] !w-[145px] !font-medium"
                                key="button1"
                              >
                                <div className="center">VOIR MA PAGE</div>
                              </button>
                            </Link>{" "}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  } else {
    return (
      <div className="flex w-full grow justify-center items-center">
        <CircularProgress size={60} thickness={4} />
      </div>
    );
  }
};

export default Profile;
