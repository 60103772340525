import React, { useState, useEffect } from "react";
import PropertyCard from "../components/PropertyCard";
import { API } from "aws-amplify";
import CircularProgress from "@mui/material/CircularProgress";
import { BUILD_ENV, hostname } from "../App";
import useAuth from "../hooks/useAuth";
import HostNavBar from "../components/HostNavBar";
import useAd from "../hooks/useAd";
import CompleteAnAdModal from "../components/complete-ad/CompleteAnAdModal";
import AdModal from "../components/ad/CreateAnAdModal";

const Properties = () => {
  const [properties, setProperties] = useState(null);
  const [propertiesLimit, setPropertiesLimit] = useState(12);
  const { currentUser } = useAuth();
  const { setActiveStep, startAd, setStartAd } = useAd();

  useEffect(() => {
    const getPropertiesByHost = async () => {
      try {
        const apiName = "propertiesManager";
        const path = `/propertiesOfAHost/${currentUser?.uid}`;

        let response;

        if (BUILD_ENV === "localhost") {
          response = await fetch(`http://${hostname}:3001${path}`);
          response = await response.json();
        } else {
          response = await await API.get(apiName, path);
        }

        if (response) {
          setProperties(response);
        } else {
          throw new Error("No matching properties.");
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (currentUser) {
      getPropertiesByHost();
    }
  }, [currentUser]);
  
  useEffect(() => {
    const createAnAdData = window.localStorage.getItem(
      `CREATE_AN_AD_STATE_${currentUser?.uid}`
    );
    if (createAnAdData !== null)
      setStartAd({
        ...startAd,
        ...JSON.parse(createAnAdData),
        picturesFiles: [],
        picturesUrls: [],
      });
  }, []);

  const handlePropertiesLimit = () => {
    return setPropertiesLimit(propertiesLimit + 12);
  };

  return (
    <>
      <AdModal />
      <CompleteAnAdModal />

      <div className="flex flex-col grow" id="properties">
        <HostNavBar focused="properties" />

        {properties === null ? (
          <div className="flex grow w-full h-full justify-center items-center">
            <CircularProgress size={60} thickness={4} />
          </div>
        ) : (
          <main className="max-w-bigScreen mx-auto px-6 md:px-10 lg:px-16 w-full">
            <div className="md:flex justify-between items-center border-b py-4 lg:py-6">
              {properties?.length === 0 ? (
                <h2 className="text-[24px] lg:text-[32px] pt-4">
                  Aucune annonce pour le moment
                </h2>
              ) : (
                <h4 className="font-bold">
                  {properties?.length +
                    (properties?.length > 1 ? " annonces" : " annonce")}
                </h4>
              )}

              <button
                className="purple-button center !hidden lg:!flex gap-2 transition-none !w-[220px]"
                onClick={() => setActiveStep(0)}
              >
                <img
                  className="w-6 h-6"
                  src="/images/navigation-signs/white-add-icon.svg"
                  alt="sticker cloud"
                />
                CR{"\u00c9"}ER UNE ANNONCE
              </button>
            </div>

            {properties?.length > 0 && (
              <>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-8 gap-y-8 lg:gap-y-12 pt-6 lg:pt-10">
                  {properties?.slice(0, propertiesLimit).map((property) => (
                    <PropertyCard
                      key={property?.uid}
                      data={property}
                      statusLabel={true}
                      coHostLabel={
                        property.userUid !== currentUser?.uid ? true : false
                      }
                    />
                  ))}
                </div>

                {propertiesLimit < properties?.length && (
                  <div
                    className="pt-10 lg:pt-16 flex justify-center"
                    onClick={handlePropertiesLimit}
                  >
                    <button className="rounded-full bg-main/main-100 text-white py-2 px-6 lg:py-3 lg:px-7 font-semibold">
                      AFFICHER PLUS
                    </button>
                  </div>
                )}
              </>
            )}
          </main>
        )}
      </div>
    </>
  );
};

export default Properties;
