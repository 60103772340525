import React, { useState, useEffect } from "react";
import AdModal from "../ad/AdModal";
import useBooking from "../../hooks/useBooking";
import useAd from "../../hooks/useAd";
import useAuth from "../../hooks/useAuth";
import NextButton from "../ad/NextButton";
import { InputAdornment, TextField } from "@mui/material";
import { IonIcon } from "@ionic/react";
import { mailOutline } from "ionicons/icons";
import * as validator from "validator";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const CoHostContent = () => {
  const {
    completeAd,
    handleNext,
    setActiveStep,
    isEdit,
    editAd,
    setEditAd,
    close,
    updatePropertyFromDatabase,
    getProperty,
  } = useAd();
  const { createNewCalendarInS3 } = useBooking();
  const { currentUser, checkUserByEmail, getUserInDatabase } = useAuth();
  const [coHostContact, setCoHostContact] = useState(null);
  const [coHostsData, setCoHostsData] = useState([
    { email: "", emailError: "" },
  ]);

  useEffect(() => {
    const getCoHostsData = async () => {
      const coHostsUids = [...editAd?.coHosts];
      const newCoHostsData = [];

      for (let index = 0; index < coHostsUids.length; index++) {
        const coHostUid = coHostsUids[index];
        const data = await getUserInDatabase(coHostUid);

        if (data) {
          newCoHostsData.push({ email: data.email, emailError: "" });

          if (editAd?.mainContact === coHostUid) {
            setCoHostContact(index);
          }
        }
      }

      setCoHostsData(newCoHostsData);
    };

    if (isEdit && editAd?.coHosts.length > 0) {
      getCoHostsData();
    }
  }, [editAd, isEdit]);

  const validate = async () => {
    let isValid = true;
    let newCoHostContact = "";
    const newCoHosts = [];

    if (coHostsData[0]?.email !== "" || coHostsData.length > 1) {
      const promises = coHostsData.map(async (coHost, index) => {
        if (!validator.isEmail(coHost.email)) {
          setCoHostsData((prevState) => {
            const newData = [...prevState];
            newData[index].emailError = "L'adresse e-mail n'est pas valide.";
            return newData;
          });

          isValid = false;
        } else {
          try {
            const res = await checkUserByEmail(coHost.email);

            if (res?.response) {
              newCoHosts.push(res?.response.uid);

              if (index === coHostContact) {
                newCoHostContact = res?.response.uid;
              }
            } else {
              setCoHostsData((prevState) => {
                const newData = [...prevState];
                newData[index].emailError =
                  "Ce co-hôte n’est pas inscrit sur SlotR.";
                return newData;
              });
              isValid = false;
            }
          } catch (error) {
            console.log(error);
            setCoHostsData((prevState) => {
              const newData = [...prevState];
              newData[index].emailError =
                "Une erreur s'est produite lors de la vérification de l'utilisateur.";
              return newData;
            });
            isValid = false;
          }
        }
      });

      await Promise.all(promises);
    }

    if (isValid) {
      if (!isEdit) {
        try {
          const propertyUid = completeAd?.uid;
          const calendarUrl = await createNewCalendarInS3(currentUser?.uid);

          await updatePropertyFromDatabase(propertyUid, {
            ...completeAd,
            coHosts: newCoHosts,
            mainContact: newCoHostContact,
            calendar: calendarUrl,
            status: "published",
          });

          const propertyData = await getProperty(propertyUid);

          if (
            propertyData.hasOwnProperty("calendar") &&
            propertyData?.calendar.startsWith("http")
          ) {
            window.localStorage.removeItem(`COMPLETE_AD_STATE_${propertyUid}`);
            handleNext();
          } else {
            const newCalendarUrl = await createNewCalendarInS3(
              currentUser?.uid
            );

            await updatePropertyFromDatabase(propertyUid, {
              ...completeAd,
              coHosts: newCoHosts,
              mainContact: newCoHostContact,
              calendar: newCalendarUrl,
              status: "published",
            });
          }
        } catch (error) {
          setActiveStep(17);
          console.error(error);
        }
      } else {
        if (
          editAd.coHosts !== newCoHosts ||
          editAd.mainContact !== newCoHostContact
        ) {
          const newValues = {
            coHosts: newCoHosts,
            mainContact: newCoHostContact,
          };

          const res = await updatePropertyFromDatabase(editAd?.uid, newValues);

          if (res) {
            setEditAd({
              ...editAd,
              ...newValues,
            });
          }
        }

        close();
      }
    }
  };

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setCoHostContact(Number(value));
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;

    setCoHostsData((prevState) => {
      const newData = [...prevState];
      newData[index].email = value;
      newData[index].emailError = "";
      return newData;
    });
  };

  const adNewCoHost = () => {
    const nbOfCoHosts = coHostsData?.length;

    if (nbOfCoHosts < 3) {
      setCoHostsData((prevState) => [
        ...prevState,
        { email: "", emailError: "" },
      ]);
    }
  };

  const removeCoHost = (index) => {
    setCoHostsData((prevState) => {
      const newData = [...prevState];
      newData.splice(index, 1);
      return newData;
    });

    if (index <= coHostContact) {
      setCoHostContact(null);
    }
  };

  return (
    <div className="space-y-6 md:px-4 h-full flex flex-col justify-between">
      <div className="space-y-10">
        <div className="space-y-2">
          <h4 className="text-center">Ajouter un co-hôte</h4>

          <p className="text-sm text-design-system/highlight text-center">
            Par défaut, la personne qui crée l’annonce est le contact principal
            de l’annonce.
          </p>
        </div>

        <div className="flex flex-col overflow-y-auto	scrollbar-hidden space-y-6">
          <RadioGroup value={coHostContact} onChange={handleRadioChange}>
            <div className="flex flex-col space-y-6">
              {coHostsData?.map((coHost, index) => (
                <div className="space-y-1" key={index}>
                  <ContactField
                    value={coHost.email}
                    onChange={(e) => handleInputChange(e, index)}
                    remove={() => removeCoHost(index)}
                    error={coHost.emailError}
                    showRemoveButton={true}
                  />

                  <FormControlLabel
                    value={index}
                    control={<Radio />}
                    label={
                      <span className="text-sm pt-1">
                        Définir comme contact principal
                      </span>
                    }
                  />
                </div>
              ))}
            </div>
          </RadioGroup>

          {coHostsData?.length < 3 && (
            <div className="flex w-full justify-center">
              <button onClick={adNewCoHost}>
                <img
                  src="/images/add-icon.svg"
                  alt="add-icon"
                  className="w-8 h-8"
                />
              </button>
            </div>
          )}
        </div>
      </div>

      {!isEdit ? (
        <div className="flex w-full justify-end pt-6">
          <NextButton handleClick={validate} />
        </div>
      ) : (
        <div className="flex w-full justify-center pt-10">
          <button
            className="purple-button center relative left-0 !w-[111px]"
            onClick={validate}
          >
            VALIDER
          </button>
        </div>
      )}
    </div>
  );
};

const ContactField = ({ value, onChange, error, remove, showRemoveButton }) => {
  return (
    <div>
      <div className="space-y-1">
        <label className="text-sm">Adresse de contact</label>

        <div className="flex space-x-6 items-center ">
          <TextField
            error={Boolean(error)}
            value={value}
            placeholder="Email*"
            onChange={onChange}
            sx={{
              "& .MuiFormHelperText-root": {
                color: "var(--error-color)",
                margin: "1px",
              },
            }}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IonIcon
                    icon={mailOutline}
                    className="w-6 h-6 text-main/dark-100 p-[1px]"
                  ></IonIcon>
                </InputAdornment>
              ),
            }}
            type="email"
            InputLabelProps={{ shrink: false }}
          />

          {showRemoveButton && (
            <button onClick={remove}>
              <img
                src="/images/less-icon.svg"
                alt="add-icon"
                className="w-8 h-8"
              />
            </button>
          )}
        </div>
      </div>

      <p
        className={`text-semantic/error text-xs pt-1 ${
          error ? "flex" : "hidden"
        }`}
      >
        {error}
      </p>
    </div>
  );
};

const CoHost = ({}) => {
  return (
    <AdModal children={<CoHostContent />} stepNumber={15} height="860px" />
  );
};

export default CoHost;
