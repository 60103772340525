import { useState, useRef, useEffect } from "react";
import imageCompression from "browser-image-compression";
import useAd from "../hooks/useAd";
import useAuth from "../hooks/useAuth";
import { API } from "aws-amplify";
import { BUILD_ENV } from "../App";
import { hostname } from "../App";
const FullScreenProfilePhoto = ({ imageUrl, onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-75 z-50">
      <img src={imageUrl} alt="Full Screen" className="max-w-full max-h-full" />
      <button
        onClick={onClose}
        className="absolute top-4 right-4 bg-white rounded-full border-2 border-white"
      >
        <img src="/images/close-circle-add-pictures.svg" className="w-[30px]" />
      </button>
    </div>
  );
};
const uploadPicturesToS3 = async (uid, picture) => {
  if (picture) {
    try {
      const formData = new FormData();
      formData.append("uid", uid);
      formData.append("file", picture);

      const apiName = "propertiesManager";
      const path = `/uploadPictures`;
      let options = {
        body: formData,
      };
      let response;

      if (BUILD_ENV === "localhost") {
        options["method"] = "POST";

        response = await fetch(`http://${hostname}:3001${path}`, options);
        response = await response.json();
      } else {
        response = await API.post(apiName, path, options);
      }

      return response;
    } catch (error) {
      console.log("Error uploading file:", error);
    }
  }
};
const ProfilePicture = () => {
  const [showMobileMenuPhoto, setShowMobileMenuPhoto] = useState(false);
  const [showFullScreenPhoto, setShowFullScreenPhoto] = useState(false);
  const [pictureUrl, setPictureUrl] = useState("");
  const { currentUser, UpdateUserInDatabase } = useAuth();
  const fileInputRef = useRef(null);
  const isTraveler = currentUser?.userType === "traveler";
  useEffect(() => {
    if (currentUser) {
      setPictureUrl(
        currentUser?.profilePicture ||
          `/images/${
            isTraveler
              ? "sloth-profile-traveler-picture.svg"
              : "sloth-profile-host-picture.svg"
          }`
      );
    }
  }, [currentUser]);
  async function handleImageUpload(image) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(image, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }
  async function handlePhotoChange(e) {
    const image = e.target.files[0];
    const maxFileSize = 10;
    const fileType = image?.type;
    const allowedExtension = ["image/jpeg", "image/jpg", "image/png"];
    const fileSize = (image?.size / 1024 / 1024).toFixed(2);

    if (allowedExtension.indexOf(fileType) > -1) {
      try {
        const compressedFile = await handleImageUpload(image);
        const pictureUrl = await uploadPicturesToS3(
          currentUser?.uid,
          compressedFile
        );
        setPictureUrl(pictureUrl);
        await UpdateUserInDatabase(currentUser?.uid, {
          profilePicture: pictureUrl,
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      alert(`Seuls les fichiers aux formats .png, .jpg et .jpeg`);
    }
  }
  return (
    <>
      {showFullScreenPhoto && (
        <FullScreenProfilePhoto
          imageUrl={pictureUrl}
          onClose={() => {
            setShowFullScreenPhoto(false);
          }}
        />
      )}
      <div className="w-[80px] h-[80px] lg:w-[160px] lg:h-[160px] relative center ">
        <img
          className=" object-fill w-full h-full rounded-full"
          src={pictureUrl}
          alt="sloth host profile picture"
        />
        <input
          type="file"
          accept=".jpg, .png, .jpeg"
          onChange={handlePhotoChange}
          style={{ display: "none" }}
          ref={fileInputRef}
        />
        <div className="hidden lg:flex lg:justify-center lg:items-center absolute w-full h-full   ">
          <button
            className=" rounded-full border-white border-2 h-[40px] px-5  center  text-white text-[12px] font-semibold tracking-widest hover:bg-white hover:text-black"
            onClick={() => fileInputRef.current.click()}
          >
            MODIFIER
          </button>
        </div>
        <div className=" lg:hidden absolute bottom-0 right-0  ">
          {!showMobileMenuPhoto ? (
            <button
              className=" rounded-full bg-[#CECECE] h-[22px] w-[22px] center text-[20px]  right-0"
              onClick={() => {
                setShowMobileMenuPhoto(true);
              }}
            >
              +
            </button>
          ) : (
            <div className="absolute -mt-5 -ml-2 w-[175px] flex flex-col bg-white border border-main/main-100 rounded-lg p-4 space-y-2 z-30">
              <button
                onClick={() => {
                  setShowFullScreenPhoto(true);
                  setShowMobileMenuPhoto(false);
                }}
                className="text-left	"
              >
                Voir la photo
              </button>
              <hr />
              <button
                onClick={() => {
                  fileInputRef.current.click();
                  setShowMobileMenuPhoto(false);
                }}
              >
                Changer la photo
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default ProfilePicture;
