import { useState } from "react";
import useAd from "../../hooks/useAd";
import { useParams } from "react-router-dom";

const AvailabilityRange = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const availabilityRangeOptions = [24, 12, 9, 6, 3];
  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };
  const { editAd } = useAd();
  return (
    <div className=" pt-[24px] space-y-[4px]">
      <p className="text-base font-semibold">Plage de disponibilité</p>
      <p className="text-neutral/gray/gray-100 text-xs ">
        Combien de temps les voyageurs peuvent-ils réserver à l'avance ?
      </p>

      {!showDropdown ? (
        <button
          onClick={toggleDropdown}
          className="flex justify-between border-neutral/gray/gray-40 border-[1px] py-[8px] px-[16px] w-full"
        >
          <p className="text-sm">
            {editAd.hasOwnProperty("availabilityRange")
              ? editAd?.availabilityRange
              : 24}{" "}
            mois à l'avance
          </p>
          <img
            src="/images/navigation-signs/arrowDownBlack.svg"
            className="w-[24px]"
          />
        </button>
      ) : (
        <div className="border-main/main-100 border-[1px]">
          {availabilityRangeOptions.map((availabilityRange) => {
            return (
              <DropDownSelection
                availabilityRange={availabilityRange}
                toggleDropdown={toggleDropdown}
                key={availabilityRange}
                selected={
                  editAd.hasOwnProperty("availabilityRange")
                    ? availabilityRange === editAd?.availabilityRange
                    : availabilityRange === 24
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
const DropDownSelection = ({ availabilityRange, toggleDropdown, selected }) => {
  const { editAd, setEditAd, updatePropertyFromDatabase } = useAd();
  const { propertyUid } = useParams();
  const handleClick = async () => {
    toggleDropdown();
    setEditAd({ ...editAd, availabilityRange: availabilityRange });
    await updatePropertyFromDatabase(propertyUid, {
      availabilityRange: availabilityRange,
    });
  };
  return (
    <button
      onClick={handleClick}
      className={`text-left	 border-main/dark-20 border-[1px] py-[16px] px-[8px] text-sm w-full ${
        selected && "bg-main/main-100 text-white"
      }`}
    >
      {availabilityRange} mois à l'avance
    </button>
  );
};
export default AvailabilityRange;
