import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import CarouselSlider from "../components/CarouselSlider";
import { IonIcon } from "@ionic/react";
import {
  arrowForwardOutline,
  chevronDownOutline,
  chevronUpOutline,
  ellipse,
} from "ionicons/icons";
import ServiceAndCategoryCard from "../components/ServiceAndCategoryCard";
import useWindowsSize from "../hooks/useWindowsSize";
import Gallery from "../components/property-details/Gallery";
import CircularProgress from "@mui/material/CircularProgress";
import { BUILD_ENV, hostname } from "../App";
import MenuScroll from "../components/MenuScroll";
import HeroTitle from "../components/HeroTitle";
import BookingDetails from "../components/property-details/BookingDetails";
import MapContainer from "../components/MapContainer";
import { Link } from "react-router-dom";

function PropertyDetails() {
  const navigate = useNavigate();
  const screenSize = useWindowsSize();
  const { propertyUid } = useParams();
  const [property, setProperty] = useState(null);
  const [host, setHost] = useState(null);
  const [limitNbOfServices, setLimitNbOfServices] = useState();
  const [dropdownService, setDropdownService] = useState(false);
  const [learnMore, setLearnMore] = useState(false);
  const [propertyPrice, setPropertyPrice] = useState();

  const sections = [
    { name: "Description", id: "description" },
    { name: "Services", id: "services" },
    { name: "Détails du voyage", id: "travel-details" },
    { name: "Localisation", id: "location" },
    { name: "Politique et règlements", id: "policy" },
  ];

  useEffect(() => {
    const getProperty = async () => {
      try {
        const apiName = "propertiesManager";
        const path = `/properties/${propertyUid}`;

        let data;

        if (BUILD_ENV === "localhost") {
          data = await fetch(`http://${hostname}:3001${path}`);
          data = await data.json();
        } else {
          data = await API.get(apiName, path);
        }

        if (data && data?.status === "published") {
          setProperty(data);
          setPropertyPrice(data?.price);
        } else {
          throw new Error("No matching property.");
        }
      } catch (error) {
        console.log(error);
        navigate("/error");
      }
    };

    getProperty();
  }, [propertyUid]);

  useEffect(() => {
    const getHost = async () => {
      try {
        const apiName = "usersManager";
        const path = `/users/${property?.userUid}`;

        let data;

        if (BUILD_ENV === "localhost") {
          data = await fetch(`http://${hostname}:3002${path}`);
          data = await data.json();
        } else {
          data = await API.get(apiName, path);
        }

        if (data) {
          setHost(data);
        } else {
          setHost({}); //a enlever apres
          throw new Error("No matching host.");
        }
      } catch (error) {
        console.log(error);
        //navigate("/error"); a remettre apres
      }
    };

    if (property) {
      getHost();
    }
  }, [property]);

  function handleDropdown() {
    return setDropdownService(!dropdownService);
  }

  useEffect(() => {
    if (dropdownService) {
      setLimitNbOfServices(property?.services.length);
    } else {
      if (screenSize >= 768) {
        setLimitNbOfServices(7);
      } else {
        setLimitNbOfServices(4);
      }
    }
  }, [dropdownService, property]);

  function handleLearnMore() {
    return setLearnMore(true);
  }

  const TextWithClamp = ({ text }) => {
    const textRef = useRef(null);
    const [isClamped, setIsClamped] = useState(false);

    useEffect(() => {
      if (textRef.current) {
        const originalHeight = textRef.current.clientHeight;

        const numberOfLines = originalHeight / 24;

        setIsClamped(numberOfLines > 6);
      }
    }, [text]);

    return (
      <>
        <pre
          ref={textRef}
          className={`leading-6 ${isClamped && !learnMore && "line-clamp-6"}`}
        >
          {text}
        </pre>

        {!learnMore && isClamped && (
          <button
            className="flex items-center space-x-2"
            onClick={handleLearnMore}
          >
            <span>En savoir plus</span>

            <IonIcon
              icon={arrowForwardOutline}
              className="w-5 h-5 text-main/main-100"
            ></IonIcon>
          </button>
        )}
      </>
    );
  };

  return (
    <>
      {!host ? (
        <div className="flex w-full grow justify-center items-center">
          <CircularProgress size={60} thickness={4} />
        </div>
      ) : (
        <main className="flex flex-col lg:space-y-10" id="property-details">
          {/*Title page */}
          <HeroTitle
            title={"Détails"}
            maxWidth={"max-w-smallScreen"}
            hidden={"hidden lg:flex"}
          />

          <div className="max-w-smallScreen mx-auto w-full lg:px-16 lg:space-y-4">
            {/*Title Dekstop version */}
            <div className="hidden lg:flex flex-col px-6 lg:px-0 w-full">
              <h1 className="font-bold">{property?.title}</h1>

              <div className="flex space-x-2 items-center">
                <p className="">{property?.city}</p>

                <IonIcon
                  icon={ellipse}
                  className="flex-none w-[3px] h-[3px] text-[#262C36]"
                ></IonIcon>

                <p>{property?.category}</p>

                <IonIcon
                  icon={ellipse}
                  className="flex-none w-[3px] h-[3px] text-[#262C36]"
                ></IonIcon>

                <p>
                  {property?.entireAccomodation
                    ? "Logement entier"
                    : "Une partie du logement"}
                </p>

                <IonIcon
                  icon={ellipse}
                  className="flex-none w-[3px] h-[3px] text-[#262C36]"
                ></IonIcon>

                <p>{property?.numberOfBedrooms} chambres</p>

                <IonIcon
                  icon={ellipse}
                  className="flex-none w-[3px] h-[3px] text-[#262C36]"
                ></IonIcon>

                <p>{property?.numberOfTravelers} voyageurs</p>

                <IonIcon
                  icon={ellipse}
                  className="flex-none w-[3px] h-[3px] text-[#262C36]"
                ></IonIcon>

                <p>{property?.numberOfBeds} lits</p>
              </div>

              <p>
                Hôte:{" "}
                <Link to={host?.pseudo ? `/host/${host?.pseudo}` : "#"}>
                  <button className="bg-neutral/gray/gray-10 rounded-full font-semibold px-2 cursor-pointer">
                    {host?.isProfessional ? host?.companyName : host?.firstName}
                  </button>
                </Link>
              </p>

              <h5>
                {propertyPrice + "€"}
                <span className="text-xs font-inter font-normal text-design-system/highlight">
                  /nuit
                </span>
              </h5>
            </div>

            <div className="lg:flex lg:space-x-6 xl:space-x-10 w-full">
              <section className="flex flex-col grow">
                {/*Gallery Dekstop version */}
                <div className="lg:pb-6">
                  <Gallery pictures={property?.picturesUrls} />
                </div>

                {/*Gallery Mobile version */}
                <div className="w-full h-[310px] md:h-[410px] mb-6 lg:hidden">
                  <CarouselSlider
                    pictures={property?.picturesUrls}
                    backButton={true}
                  />
                </div>

                {/*Title mobile version */}
                <div className="flex space-x-4 px-6 lg:px-0 lg:hidden pb-6">
                  <div className="w-3/4">
                    <h5 className="font-bold">{property?.title}</h5>

                    <p>{property?.city + ", " + property?.country}</p>

                    <p>
                      Hôte:{" "}
                      <Link to={!host?.pseudo ? `/host/${host?.pseudo}` : "#"}>
                        <button className="bg-neutral/gray/gray-10 rounded-full font-semibold px-2 cursor-pointer">
                          {host?.isProfessional
                            ? host?.companyName
                            : host?.firstName}
                        </button>
                      </Link>
                    </p>
                  </div>

                  <div className="w-1/4">
                    <h6 className="font-semibold">
                      {propertyPrice + "€"}
                      <span className="text-sm text-design-system/highlight font-normal">
                        /nuit
                      </span>
                    </h6>
                  </div>
                </div>

                <div className="flex flex-col space-y-7 lg:space-y-12">
                  {/*Navigation and desctription section*/}
                  <div className="space-y-4 pl-6 lg:pl-0">
                    <MenuScroll
                      sections={sections}
                      defautlValue="description"
                    />

                    <div className="space-y-4 pr-5" id="description">
                      <h5 className="font-semibold">Description</h5>

                      <TextWithClamp text={property?.description} />
                    </div>
                  </div>

                  {/*Services section*/}
                  <div className="space-y-4 px-6 lg:px-0" id="services">
                    <h5 className="font-semibold">Services</h5>

                    <div className={`grid grid-cols-4 md:grid-cols-7 gap-4`}>
                      {property?.services
                        .slice(0, limitNbOfServices)
                        .map((service, index) => (
                          <ServiceAndCategoryCard
                            key={index}
                            name={service}
                            type={"service"}
                          />
                        ))}
                    </div>

                    {((screenSize >= 768 && property?.services.length > 7) ||
                      (screenSize < 768 && property?.services.length > 4)) && (
                      <button
                        className="flex items-center space-x-2 cursor-pointer"
                        onClick={handleDropdown}
                      >
                        <span>Voir tous les services</span>

                        {dropdownService ? (
                          <IonIcon
                            icon={chevronUpOutline}
                            className="w-5 h-5 text-main/main-100"
                          ></IonIcon>
                        ) : (
                          <IonIcon
                            icon={chevronDownOutline}
                            className="w-5 h-5 text-main/main-100"
                          ></IonIcon>
                        )}
                      </button>
                    )}
                  </div>

                  {/*Travel details mobile version*/}
                  <div className="flex lg:hidden" id="travel-details">
                    <BookingDetails
                      property={property}
                      host={host}
                      onChangePriceNight={(value) => setPropertyPrice(value)}
                    />
                  </div>

                  {/*Localisation mobile version*/}
                  <div className="px-6 lg:px-0 space-y-4" id="location">
                    <h5 className="font-semibold">Localisation</h5>

                    <p>
                      L’adresse exacte sera communiquée après la réservation
                      effectuée.
                    </p>

                    <div className="w-full h-[225px] md:h-[325px] xl:h-[525px]">
                      <MapContainer properties={[property]} />
                    </div>
                  </div>

                  {/*Policy and regulations section*/}
                  <div className="px-6 lg:px-0 space-y-4" id="policy">
                    <h5 className="font-semibold">Politique et règlements</h5>

                    <ul className="space-y-2">
                      <li className="flex space-x-2">
                        <IonIcon
                          icon={ellipse}
                          className="flex-none w-[7px] h-[7px] mt-2 text-main/main-100"
                        ></IonIcon>

                        <p>
                          {parseInt(property?.termsOfCancelation) > 0
                            ? "Vous disposez d'un délai maximum de " +
                              parseInt(property?.termsOfCancelation) +
                              (parseInt(property?.termsOfCancelation) > 1
                                ? " jours"
                                : " jour") +
                              " avant la date de la réservation pour annuler sans frais supplémentaires."
                            : "Veuillez noter que toute annulation de votre part ne donnera pas droit à un remboursement."}
                        </p>
                      </li>

                      {property?.rulesOfProcedure && (
                        <li className="flex space-x-2">
                          <IonIcon
                            icon={ellipse}
                            className="flex-none w-[7px] h-[7px] mt-2 text-main/main-100"
                          ></IonIcon>

                          <p>{property?.rulesOfProcedure}</p>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </section>

              {/*Travel details Desktop version*/}
              <section className="hidden lg:inline-block relative w-full lg:max-w-[360px] xl:max-w-[410px] flex-none space-y-10">
                <BookingDetails
                  property={property}
                  host={host}
                  onChangePriceNight={(value) => setPropertyPrice(value)}
                />
              </section>
            </div>
          </div>
        </main>
      )}
    </>
  );
}

export default PropertyDetails;
