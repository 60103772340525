import React, { useState, useEffect } from "react";
import PropertyCard from "../components/PropertyCard";
import { IonIcon } from "@ionic/react";
import {
  expandOutline,
  contractOutline,
  locationOutline,
} from "ionicons/icons";
import CircularProgress from "@mui/material/CircularProgress";
import useAd from "../hooks/useAd";
import MapContainer from "../components/MapContainer";
import useAuth from "../hooks/useAuth";
import { useParams } from "react-router-dom";

const Showcase = () => {
  const [propertiesLimit, setPropertiesLimit] = useState(12);
  const [properties, setProperties] = useState([]);
  const [fullSizeMap, setFullSizeMap] = useState(false);
  const { getPropertiesOfAHost } = useAd();
  const { getUserByPseudo, currentUser } = useAuth();
  const [user, setUser] = useState({});
  const { pseudo } = useParams();

  const getAllProperties = async () => {
    const user_ = await getUserByPseudo(pseudo);
    setUser({ ...user_ });
    const response = await getPropertiesOfAHost(user_?.uid);
    setProperties(response);
  };
  useEffect(() => {
    getAllProperties();
  }, [pseudo]);
  return (
    <>
      <main className="flex grow ">
        {!properties ? (
          <div className="flex w-full grow justify-center items-center">
            <CircularProgress size={60} thickness={4} />
          </div>
        ) : (
          <>
            <section
              className={`${
                !fullSizeMap && "hidden"
              } lg:block w-full lg:w-[20%]   grow h-[calc(100vh-160px)] lg:h-[calc(100vh-180px)] sticky  z-30`}
            >
              {properties?.length > 0 && (
                <MapContainer
                  properties={properties}
                  filters={{ lat: 46.227638, lng: 2.213749 }}
                />
              )}

              <div className="hidden lg:flex absolute bottom-10 justify-center w-full">
                <button
                  className="flex space-x-2 items-center purple-button !text-xs !h-[48px] !w-[210px]"
                  onClick={() => setFullSizeMap(fullSizeMap ? false : true)}
                >
                  {fullSizeMap ? (
                    <img
                      src="/images/grid-icon.svg"
                      alt="grid-icon"
                      className="w-6 h-6"
                    />
                  ) : (
                    <IonIcon
                      icon={fullSizeMap ? contractOutline : expandOutline}
                      className="w-6 h-6 text-white"
                    ></IonIcon>
                  )}

                  <span>
                    {fullSizeMap ? "Retour à la liste" : "Agrandir la carte"}
                  </span>
                </button>
              </div>
            </section>

            <section
              className={`flex flex-col w-full lg:w-[70%] xl:w-[75%] 2xl:max-w-[1700px] lg:space-y-10 flex-none px-6 md:px-10 py-6 ${
                fullSizeMap && "hidden"
              }`}
            >
              <div className="flex flex-row gap-[26px] ">
                <div className="hidden lg:block">
                  <img
                    className=" w-[80px] h-[80px] lg:w-[88px] lg:h-[88px] rounded-full "
                    src={
                      currentUser?.profilePicture ||
                      "/images/sloth-profile-host-picture.svg"
                    }
                    alt="sloth host profile picture"
                  />
                </div>
                <div className="grow max-w-[1300px]">
                  <h1 className="pb-2">
                    {user?.isProfessional
                      ? user?.companyName
                      : user?.firstName + " " + user?.lastName}
                  </h1>
                  <h6 className="pb-5">
                    {user?.isProfessional
                      ? "Hôte professionnel"
                      : "Hôte particulier"}
                  </h6>
                  <div className="flex-col gap-5 lg:flex-row flex justify-between  ">
                    {user?.description && (
                      <>
                        <p className="max-w-[762px] hidden lg:block">
                          {user?.description}
                        </p>
                        <div className="lg:hidden">
                          <ParagraphWithButton text={user?.description} />
                        </div>
                      </>
                    )}

                    <AccountVerification
                      showCol={user?.description}
                      identityVerified={
                        user?.dataOfMerchant?.status == "verified"
                      }
                      emailVerified={user?.emailVerified}
                    />
                  </div>

                  <div className="pr-8">
                    <ShareButton />
                  </div>
                </div>
              </div>
              <div className="top-[159px]  lg:top-[180px] z-30 flex lg:items-center lg:justify-between space-x-4    ">
                <div className="flex space-x-2 items-center whitespace-nowrap overflow-hidden w-full">
                  <p className="hidden lg:block font-bold text-base">
                    {properties?.length + " biens"}
                  </p>

                  <p className="block lg:hidden font-bold py-4 text-center w-full ">
                    {properties?.length + " biens"}
                  </p>
                </div>
              </div>

              <div className="flex space-y-10  grow">
                {properties?.length === 0 ? (
                  <div className="flex justify-center items-center pb-10 px-6 md:px-10">
                    <div className="flex flex-col space-y-8 md:w-2/3 items-center text-justify">
                      <p></p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 sm:gap-x-8 lg:gap-x-10 gap-y-8 lg:gap-y-10 pb-10 lg:pb-16">
                      {properties.slice(0, propertiesLimit)?.map((property) => (
                        <PropertyCard
                          key={property?.uid}
                          data={property}
                          FiltersData={{}}
                        />
                      ))}
                    </div>

                    {propertiesLimit < properties?.length && (
                      <div
                        className="pb-10 lg:pb-16 flex justify-center"
                        onClick={() => setPropertiesLimit(propertiesLimit + 12)}
                      >
                        <button className="purple-button !w-[140px] lg:!text-sm lg:!h-[48px] lg:!w-[180px]">
                          AFFICHER PLUS
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </section>

            <div className="flex lg:hidden fixed bottom-[100px] z-[50] justify-center w-full">
              <button
                className="flex space-x-2 items-center purple-button !text-xs !h-[48px] !w-[120px]"
                onClick={() => setFullSizeMap(fullSizeMap ? false : true)}
              >
                {fullSizeMap ? (
                  <img
                    src="/images/grid-icon.svg"
                    alt="grid-icon"
                    className="w-6 h-6"
                  />
                ) : (
                  <IonIcon
                    icon={locationOutline}
                    className="w-6 h-6 text-white"
                  ></IonIcon>
                )}

                <span>{fullSizeMap ? "liste" : "carte"}</span>
              </button>
            </div>
          </>
        )}
      </main>
    </>
  );
};

const ShareButton = () => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    // Copy URL to clipboard
    navigator.clipboard.writeText(window.location.href);

    // Set copied state to true
    setCopied(true);

    // Reset copied state to false after 5 seconds
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <button onClick={handleClick} disabled={copied}>
      {copied ? (
        <div className="flex gap-2 pt-4">
          <img
            src="/images/navigation-signs/green-checked-mark-circle.svg"
            className="w-6"
          ></img>
          <p id="share">lien copié</p>
        </div>
      ) : (
        <div className="flex gap-2 pt-4">
          <img src="/images/navigation-signs/share.svg"></img>
          <p>Partager</p>
        </div>
      )}
    </button>
  );
};
const AccountVerification = ({ showCol, identityVerified, emailVerified }) => {
  return (
    (emailVerified || identityVerified) && (
      <div
        className={`${
          showCol ? "w-80" : "w-fit"
        }  h-min px-6 py-2 rounded-2xl border border-zinc-300 space-y-2`}
      >
        <div className="font-semibold ">Vérification du compte</div>
        <div
          className={`${
            showCol ? "flex-col" : "flex-col md:flex-row "
          }  justify-start items-start gap-1 inline-flex`}
        >
          {identityVerified && (
            <div className="justify-start items-center gap-4 inline-flex">
              <img
                src="/images/navigation-signs/green-checked-mark.svg"
                className="w-5"
              ></img>

              <p className="w-32 text-black text-sm font-normal font-['Poppins'] leading-snug">
                Identité vérifiée
              </p>
            </div>
          )}
          {emailVerified && (
            <div className="justify-start items-center gap-4 inline-flex">
              <img
                src="/images/navigation-signs/green-checked-mark.svg"
                className="w-5"
              ></img>

              <p className="text-black text-sm font-normal font-['Poppins'] leading-snug">
                Adresse email vérifiée
              </p>
            </div>
          )}
        </div>
      </div>
    )
  );
};
const ParagraphWithButton = ({ text }) => {
  const [expanded, setExpanded] = useState(false);

  const shortenedText = text.slice(0, 200);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      {expanded ? <p>{text}</p> : <p>{shortenedText}</p>}
      {text.length > 200 && (
        <button onClick={handleClick} className="underline pt-2">
          {expanded ? "Voir moins" : "Voir plus"}
        </button>
      )}
    </div>
  );
};
export default Showcase;
