import React, { useState, useEffect } from "react";

const Toggle = ({
  firstValue,
  secondValue,
  onChange,
  defaultValue,
  disabled = false,
}) => {
  const [selectValue, setSelectValue] = useState(defaultValue);
  function handleSetSelectValue() {
    if (!disabled) {
      if (selectValue === firstValue) {
        return setSelectValue(secondValue);
      } else {
        return setSelectValue(firstValue);
      }
    }
  }

  useEffect(() => {
    if (onChange) {
      onChange(selectValue);
    }
  }, [selectValue]);

  return (
    <div className="flex space-x-2">
      <p
        className={`${
          selectValue === secondValue && "text-neutral/gray/gray-100"
        }`}
      >
        {firstValue}
      </p>

      <div
        className={`flex rounded-full ${
          disabled ? "bg-neutral/gray/gray-60" : "bg-main/main-100"
        } w-12 h-6 items-center px-1 cursor-pointer ${
          selectValue === firstValue ? "justify-start" : "justify-end"
        }`}
        onClick={handleSetSelectValue}
      >
        <div className="bg-white w-4 h-4 rounded-full"></div>
      </div>

      <p
        className={`${
          selectValue === firstValue && "text-neutral/gray/gray-100"
        }`}
      >
        {secondValue}
      </p>
    </div>
  );
};

export default Toggle;
