import { useState, useEffect } from "react";
import HostNavBar from "../components/HostNavBar";
import CancelationModal from "../components/bookings/cancelationModal";
import WellCanceledModal from "../components/bookings/wellCanceledModal";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useBooking from "../hooks/useBooking";
import CircularProgress from "@mui/material/CircularProgress";
import HeroTitle from "../components/HeroTitle";
import { format } from "date-fns";
import useAuth from "../hooks/useAuth";
import { IonIcon } from "@ionic/react";
import { people } from "ionicons/icons";

const Bookings = () => {
  const { pastBookings, futureBookings, pendingBookings, loading } =
    useBooking();
  const [bookingsLimit, setBookingsLimit] = useState(5);
  const { currentUser } = useAuth();
  const location = useLocation();
  const handleBookingsLimit = () => {
    return setBookingsLimit(bookingsLimit + 5);
  };
  function scrollTo(sectionId) {
    const element = document.getElementById(sectionId);

    if (element) {
      console.log("a");
      window.history.pushState({}, "", `#${sectionId}`);
      setCurrentSectionId(sectionId);
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  const [currentSectionId, setCurrentSectionId] = useState(
    location.hash.replace("#", "") || "bookings"
  );

  useEffect(() => {
    if (currentSectionId !== "bookings") {
      const timeoutId = setTimeout(() => {
        scrollTo(currentSectionId);
      }, 1000); // Adjust the delay as needed

      return () => clearTimeout(timeoutId);
    }
  }, [currentSectionId]);

  const isTraveler = currentUser?.userType === "traveler";
  return (
    <main className="flex flex-col grow" id="bookings">
      {isTraveler ? (
        <HeroTitle
          title={"Mes séjours"}
          maxWidth={"lg:w-[1440px] px-6 md:px-10 lg:px-[144px]"}
          hidden={"hidden lg:flex"}
        />
      ) : (
        <HostNavBar focused="bookings" />
      )}

      {loading ? (
        <div className="flex grow w-full h-full justify-center items-center">
          <CircularProgress size={60} thickness={4} />
        </div>
      ) : (
        <div className="lg:flex lg:justify-center lg:items-center">
          <div className="max-w-smallScreen mx-auto w-full px-6 md:px-10 ld:px-16">
            <div className="pt-4 lg:pt-[52px] space-y-14">
              {futureBookings?.length === 0 &&
                pastBookings?.length === 0 &&
                pendingBookings?.length ===
                  0(<h2>Aucune réservation pour le moment</h2>)}

              {pendingBookings?.length > 0 && (
                <div className="lg:space-y-10">
                  <h2 className="text-[24px] lg:text-[32px] md:pt-[30px]">
                    {isTraveler
                      ? pendingBookings.length > 1
                        ? "Vos séjours en attente"
                        : "Votre séjour en attente"
                      : pendingBookings.length > 1
                      ? "Vos réservations en attente"
                      : "Votre réservation en attente"}
                  </h2>
                  {pendingBookings
                    .slice(0, bookingsLimit)
                    ?.map((bookingData, index) => (
                      <Booking
                        key={index}
                        bookingData={bookingData?.booking}
                        propertyData={bookingData?.property}
                        userData={bookingData?.user}
                        isPending={true}
                        isLast={index === pendingBookings.length - 1}
                      />
                    ))}
                </div>
              )}
              {futureBookings?.length > 0 && (
                <div className="lg:space-y-10">
                  <h2 className="text-[24px] lg:text-[32px] md:pt-[30px]">
                    {isTraveler
                      ? futureBookings.length > 1
                        ? "Vos prochains séjours"
                        : "Votre prochain séjour"
                      : futureBookings.length > 1
                      ? "Vos prochaines réservations"
                      : "Votre prochaine réservation"}
                  </h2>
                  {futureBookings
                    .slice(0, bookingsLimit - pendingBookings.length)
                    ?.map((bookingData, index) => (
                      <Booking
                        key={index}
                        bookingData={bookingData?.booking}
                        propertyData={bookingData?.property}
                        userData={bookingData?.user}
                        isPending={false}
                        isLast={index === futureBookings.length - 1}
                      />
                    ))}
                </div>
              )}

              {pastBookings?.length > 0 &&
                futureBookings.length < bookingsLimit && (
                  <div className="lg:space-y-10">
                    <h2 className="text-[24px] lg:text-[32px] md:pt-[30px]">
                      {isTraveler
                        ? pastBookings.length > 1
                          ? "Vos séjours passés"
                          : "Votre séjours passé"
                        : pastBookings.length > 1
                        ? "Vos réservations passés"
                        : "Votre réservation passée"}
                    </h2>

                    {pastBookings
                      .slice(
                        0,
                        bookingsLimit -
                          futureBookings.length -
                          pendingBookings.length
                      )
                      ?.map((bookingData, index) => (
                        <Booking
                          key={index}
                          bookingData={bookingData?.booking}
                          propertyData={bookingData?.property}
                          userData={bookingData?.user}
                          isPending={false}
                          isLast={index === pastBookings.length - 1}
                        />
                      ))}
                  </div>
                )}

              {futureBookings?.length +
                pastBookings?.length +
                pendingBookings?.length >
                bookingsLimit && (
                <div
                  className="pt-10 lg:pt-16 flex justify-center"
                  onClick={handleBookingsLimit}
                >
                  <button className="purple-button !w-[140px] lg:!text-sm lg:!h-[48px] lg:!w-[180px]">
                    AFFICHER PLUS
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

const Booking = ({
  bookingData,
  propertyData,
  userData,
  isPending,
  isLast,
}) => {
  const { currentUser } = useAuth();
  const { userType } = currentUser;
  const isTraveler = userType === "traveler";
  const { checkIn, checkOut, price, numberOfTravelers } = bookingData;
  const { title, picturesUrls } = propertyData;
  const { firstName, lastName, phoneNumber, email, profilePicture } = userData;
  const startDate = new Date(checkIn);
  const endDate = new Date(checkOut);
  const [detailIsOpen, setDetailIsOpen] = useState(false);
  const [cancelationModalStep, setCancelationModalStep] = useState(0);
  const priceToShow =
    price?.totalPrice -
      (price?.slotrFee + price?.tourismTax + price?.vatSlotrFee) || 0;

  const getNbOfNights = () => {
    const differenceInTime = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return Math.round(differenceInDays);
  };

  const PriceRow = ({ name, price, style, stylePrice }) => {
    return (
      <div className={`${style} lg:w-[70%]`}>
        <div className="flex flex-row justify-between  p-[8px]">
          <p>{name}</p>
          <p className={`font-semibold ${stylePrice} `}>{price}&#8364;</p>
        </div>
        <hr />
      </div>
    );
  };

  const HostContent = () => {
    return (
      <div className="space-y-[8px] lg:space-y-[24px]">
        <div className="grid grid-cols-2 pt-3">
          <div>
            <p className="lg:text-[20px] font-semibold lg:font-bold">Arrivée</p>
            <div className="font-semibold">
              <p className="hidden md:block capitalize">
                {startDate &&
                  format(startDate, "EEEE dd MMMM yyyy")}
              </p>
              <p className="md:hidden">
                {startDate && format(startDate, "dd MMM yyyy")}
              </p>
            </div>
            <div>
              <p>{startDate && format(startDate, "HH:mm")}</p>
            </div>
          </div>
          <div>
            <p className="lg:text-[20px] font-semibold lg:font-bold">Départ</p>
            <div className="font-semibold">
              <p className="hidden md:block capitalize">
                {endDate && format(endDate, "EEEE dd MMMM yyyy")}
              </p>
              <p className="md:hidden">
                {endDate && format(endDate, "dd MMM yyyy")}
              </p>
            </div>
            <div>
              <p> {endDate && format(endDate, " HH:mm")}</p>
            </div>
          </div>
        </div>
        <div>
          <p className="lg:text-[20px] font-semibold lg:font-bold">Voyageurs</p>
          <p>
            {numberOfTravelers} voyageur
            {numberOfTravelers > 1 ? "s" : ""}
          </p>
        </div>

        {isPending ? (
          <div className="flex  gap-4 items-center">
            <p>Acceptez la réservation ?</p>
            <button
              className="purple-button center  text-[12px] !w-[70px]    !font-medium "
              onClick={() => (window.location = "mailto:" + email)}
            >
              Oui
            </button>
            <button
              className="secondary-purple-button center  text-[12px] !w-[70px]    !font-medium "
              onClick={() => (window.location = "mailto:" + email)}
            >
              Non
            </button>
          </div>
        ) : (
          <div>
            <p className="lg:text-[20px] font-semibold lg:font-bold">
              Revenu total
            </p>
            <div className="flex flex-row justify-between lg:grid lg:grid-cols-2 pt-1">
              <p className=" font-semibold lg:font-bold">
                {(priceToShow || 0).toFixed(2)}&#8364;
              </p>

              <button
                className="flex flex-row gap-4 cursor-pointer"
                onClick={() => {
                  setDetailIsOpen(!detailIsOpen);
                }}
              >
                <p className="center ">Détails</p>
                <div className="center">
                  {detailIsOpen ? (
                    <img
                      className=" w-[15px]    "
                      src="/images/navigation-signs/arrowUpBlack.svg"
                      alt="sloth host profile picture"
                    />
                  ) : (
                    <img
                      className="  w-[25px]    "
                      src="/images/navigation-signs/arrowDownBlack.svg"
                      alt="sloth host profile picture"
                    />
                  )}
                </div>
              </button>
            </div>

            {detailIsOpen && (
              <div>
                <hr className="border-[0.4px] mt-[10px] lg:w-[70%] border-neutral/gray/gray-100" />
                <div className="grid grid-cols-1">
                  <div className="font-semibold">
                    <PriceRow
                      name="Prix payé par le voyageur"
                      price={price.totalPrice}
                    />{" "}
                  </div>

                  <PriceRow
                    name="Taxe de séjour"
                    price={"-" + (price?.tourismTax || 0).toFixed(2)}
                  />
                  <PriceRow
                    name="TVA SlotR"
                    price={"-" + (price?.vatSlotrFee || 0).toFixed(2)}
                  />
                  <PriceRow
                    name="Frais de la plateforme SlotR"
                    price={"-" + (price?.slotrFee || 0).toFixed(2)}
                  />

                  <PriceRow
                    name="Prix total"
                    price={(priceToShow || 0).toFixed(2)}
                    style="bg-main/main-100 bg-opacity-10"
                    stylePrice="!font-bold"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const TravelerContent = () => {
    const {
      streetNumber,
      street,
      addressComplement,
      city,
      postalCode,
      country,
    } = propertyData;
    const navigate = useNavigate();

    return (
      <div className=" space-y-[8px] lg:space-y-[24px]">
        <div className="grid grid-cols-2 pt-3">
          <div>
            <p className="font-semibold ">Voyageurs</p>
            <p>
              {numberOfTravelers} voyageur
              {numberOfTravelers > 1 ? "s" : ""}
            </p>
          </div>
          <div>
            <p className=" font-semibold">Dates</p>
            <p>
              {startDate &&
                endDate &&
                format(startDate, "dd MMM yyyy") +
                  " - " +
                  format(endDate, "dd MMM yyyy")}{" "}
              ({getNbOfNights()} nuitées)
            </p>
          </div>
        </div>
        <div>
          <p className=" font-semibold pb-3">Adresse</p>
          <p>{streetNumber + " " + street + " " + addressComplement}</p>
          <p>{postalCode + " " + city}</p>
          <p>{country}</p>
        </div>
        <div className="pt-2 flex flex-col md:flex-row gap-6">
          <button
            onClick={() => {
              navigate(`/booking-summary`, {
                state: {
                  transactionUid: bookingData?.transactionUid,
                  isAfterPayment: false,
                },
              });
            }}
            className="purple-button center transition-none !text-[12px] !w-[210px] !font-medium "
          >
            VOIR MA R{"\u00c9"}SERVATION
          </button>

          {isPending && (
            <button
              className=" secondary-purple-button center transition-none text-[12px] !w-[210px] lg:!text-sm !font-medium"
              onClick={() => {
                setCancelationModalStep(1);
              }}
            >
              <div className="flex flex-row gap-3 ">
                <div className="center ">
                  <img
                    className=" w-[15px] h-[15px] "
                    src="/images/navigation-signs/delete-purple-cross.svg"
                    alt="sticker cloud"
                  />
                </div>
                <p className="center !text-[12px]">ANNULER MA DEMANDE</p>
              </div>
            </button>
          )}
        </div>
      </div>
    );
  };

  const ContactContentHost = () => {
    return (
      <div
        className={`mt-8 lg:mt-0 ${
          isPending ? "bg-main/main-20" : "bg-neutral/gray/gray-10"
        } px-[22px] py-[16px] w-full lg:min-w-[322px]  lg:max-w-[340px] h-fit rounded-[10px] flex flex-row gap-4`}
      >
        <div className="relative  top-2">
          <img
            className="w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] rounded-full"
            src={profilePicture || "/images/sloth-profile-traveler-picture.svg"}
            alt="sloth host profile picture"
          />
        </div>
        <div className="pt-[11px] text-[16px]">
          <h5>Contact</h5>
          <p className=" font-semibold pt-[16px]">
            {firstName} {lastName}
          </p>
          <p>{phoneNumber}</p>
          <p className="text-[12px]">{email}</p>
          <div className="py-[18px]">
            <button
              className="purple-button center transition-none text-[12px] !w-[130px]    !font-medium "
              onClick={() => (window.location = "mailto:" + email)}
            >
              CONTACTER
            </button>
          </div>
        </div>
      </div>
    );
  };

  const ContactContentTraveler = () => {
    return (
      <div className="mt-8 lg:mt-0  px-[22px] pb-[16px] w-full lg:min-w-[322px]  lg:max-w-[340px] h-fit rounded-[10px] flex flex-row gap-4">
        <div className="relative  top-5">
          <img
            className="w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] rounded-full"
            src={profilePicture || "/images/sloth-profile-traveler-picture.svg"}
            alt="sloth host profile picture"
          />
        </div>

        <div className="pt-[11px] text-[16px]">
          <p className=" font-semibold pt-[16px]">
            {firstName} {lastName}
          </p>

          <p>{phoneNumber}</p>

          <p className="text-[12px]">{email}</p>

          <div className="pt-[25px] pb-[5px]">
            <button
              className="secondary-purple-button center transition-none text-[12px] !w-[170px] !font-medium "
              onClick={() => (window.location = "mailto:" + email)}
            >
              CONTACTER L'HÔTE
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id={bookingData.uid} className=" md:pb-8  ">
      <CancelationModal
        cancelationModalStep={cancelationModalStep}
        setCancelationModalStep={setCancelationModalStep}
        firstName={firstName}
      />
      <WellCanceledModal
        cancelationModalStep={cancelationModalStep}
        setCancelationModalStep={setCancelationModalStep}
        firstName={firstName}
      />

      <h3 className="my-6 text-[20px] lg:text-[28px] capitalize">
        {startDate
          ? format(startDate, "EEEE dd MMMM")
          : "Date invalide"}
      </h3>

      <div
        className={`lg:flex-row lg:flex lg:justify-between  gap-6 w-full text-black  ${
          isPending && "bg-main/main-20 rounded-lg px-[16px] py-[32px]"
        }`}
      >
        <div className="lg:flex lg:flex-row gap-6 grow ">
          <div className="relative">
            <img
              src={picturesUrls && picturesUrls[0]}
              className=" h-[132px] w-full lg:w-[190px] lg:h-[307px] object-cover rounded-lg"
              alt={title}
            />

            {!isTraveler && propertyData?.userUid !== currentUser?.uid && (
              <div className="absolute top-3 right-3">
                <div className="relative group">
                  <div className="rounded-full bg-[#414141] p-1.5">
                    <IonIcon
                      icon={people}
                      className="block w-5 h-5 text-white flex-none"
                    />
                  </div>

                  <div className="absolute -bottom-8 w-24 bg-[#414141] rounded-[4px] p-1 opacity-0 transition-opacity duration-300 group-hover:block group-hover:opacity-100">
                    <p className="text-xs text-white w-full text-center">
                      Co-gestion
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className=" grow pt-[28px] lg:p-0">
            <h4
              className={`${
                isTraveler
                  ? "text-[20px] lg:text-[24px]"
                  : "text-[18px] lg:text-[34px]"
              }`}
            >
              {title}
            </h4>
            {isPending && "24h avant expiration"}
            {isTraveler ? (
              <TravelerContent isPending={isPending} />
            ) : (
              <HostContent isPending={isPending} />
            )}
          </div>
        </div>

        {isTraveler ? <ContactContentTraveler /> : <ContactContentHost />}
      </div>

      {!isTraveler && !isPending && (
        <div className="lg:block lg:relative lg:left-[18%] pt-5">
          <button
            className=" secondary-purple-button center transition-none text-[12px] !h-[40px] !w-[250px] lg:!h-[45px] lg:!w-[254px] lg:!text-sm !font-medium"
            onClick={() => {
              setCancelationModalStep(1);
            }}
          >
            <div className="flex flex-row gap-3 ">
              <div className="center -ml-2">
                <img
                  className=" w-[15px] h-[15px] "
                  src="/images/navigation-signs/delete-purple-cross.svg"
                  alt="sticker cloud"
                />
              </div>
              <p className="center text-[12px]">
                ANNULER LA R{"\u00c9"}SERVATION
              </p>
            </div>
          </button>
        </div>
      )}
      <hr className={`md:w-[60%] mt-[20px] ${isLast && "hidden"}`} />
    </div>
  );
};

export default Bookings;
